<!--
<ol class="breadcrumb" *ngIf="breadcrumb">
  <li class="breadcrumb-item" *ngFor="let ruta of breadcrumb.ruta ; let i = index ">
    <span *ngIf="breadcrumb.ruta.length - 1 > i ">
      <a routerLink="/{{ruta.ruta}}">{{ ruta.nombre }}</a>
      </span>
    <span  *ngIf="breadcrumb.ruta.length - 1 == i ">{{ ruta.nombre }}</span>
  </li>
<li class="breadcrumb-item active">
    <span tabindex="0">Dashboard</span>
  </li>
</ol>
-->

<div>
    <div class="flex flex-wrap items-center font-medium border-b-1 border-hr">
        <div>
            <a class="hover:underline whitespace-nowrap text-grey-200 cursor-pointer" routerLink="/dashboard">Home</a>
        </div>
        <div class="flex items-center ml-1 whitespace-nowrap" *ngFor="let ruta of breadcrumb.ruta ; let i = index ">
            <mat-icon
                class="icon-size-5 text-secondary"
                [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
            <a class="ml-1 text-grey-800 hover:underline cursor-pointer " routerLink="/{{ruta.ruta}}" *ngIf="breadcrumb.ruta.length - 1 > i ">{{ruta.nombre}}</a>
            <span class="font-bold"  *ngIf="breadcrumb.ruta.length - 1 == i ">{{ ruta.nombre }}</span>
        </div>

    </div>
    <!--
    <div class="flex sm:hidden">
        <a
            class="inline-flex items-center -ml-1.5 text-secondary font-medium"
            [routerLink]="'./..'">
            <mat-icon
                class="icon-size-5 text-secondary"
                [svgIcon]="'heroicons_solid:chevron-left'"></mat-icon>
            <span class="ml-1">Back</span>
        </a>
    </div>-->
</div>
