<div class="flex flex-col flex-auto p-6 shadow rounded-2xl overflow-hidden" [style.background-color]="bgColor">
    <div class="flex items-start justify-between">
        <div class="text-lg font-medium tracking-tight leading-6 truncate" [style.color]="titleColor">{{title}}</div>
        <div class="ml-2 -mt-2 -mr-3" *ngIf="addressing_route">
            <button
                mat-icon-button
                [matMenuTriggerFor]="summaryMenu">
                <mat-icon
                    class="icon-size-5 {{ classButtonColor }}"
                    [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
            </button>
            <mat-menu #summaryMenu="matMenu">
                <button mat-menu-item [routerLink]="[addressing_route]" [style.color]="addressingNameColor">Ir a {{addressing_name}}</button>



            </mat-menu>
        </div>
    </div>
    <div class="flex flex-col items-center mt-2">
        <!-- <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-hr">{{ info.users_total }}</div> -->
        <div class="text-7xl sm:text-8xl font-bold tracking-tight leading-none" [style.color]="typeNumberColor">{{type_number}}</div>
        <div class="text-lg font-medium dark:text-blue-500"  [style.color]="typeNameColor">{{type_name}}</div>
        <div class="flex items-baseline justify-center w-full mt-5 text-secondary">
            <div class="text-md font-medium truncate" *ngIf="active_name" [style.color]="activeNameColor">{{active_name}}<ng-container *ngIf="active_number && active_number != '' && active_number != '-'">:</ng-container></div>
            <div class="ml-1.5 text-lg font-semibold" [style.color]="activeNumberColor">{{active_number}}</div>
        </div>
    </div>
</div>
