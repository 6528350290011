import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            /*{
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },*/
            {
                path: 'privacy-policy',
                loadChildren: () =>
                    import(
                        'app/modules/auth/privacy-policy/privacy-policy.module'
                    ).then((m) => m.AuthPrivacyPolicyModule),
            },
            {
                path: 'cookies-policy',
                loadChildren: () =>
                    import(
                        'app/modules/auth/cookies-policy/cookies-policy.module'
                    ).then((m) => m.AuthCookiesPolicyModule),
            },
            {
                path: 'terms-of-use',
                loadChildren: () =>
                    import(
                        'app/modules/auth/terms-of-use/terms-of-use.module'
                    ).then((m) => m.AuthTermsOfUseModule),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canLoad: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'account',
                loadChildren: () =>
                    import('app/modules/account/account.module').then(
                        (m) => m.AccountModule
                    ),
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('app/modules/users/users.module').then(
                        (m) => m.UserModule
                    ),
            },
            //{path: 'dashboard', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('app/modules/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: 'companies',
                loadChildren: () =>
                    import('app/modules/companies/companies.module').then(
                        (m) => m.CompaniesModule
                    ),
            },
            {
                path: 'company',
                loadChildren: () =>
                    import('app/modules/companies/companies.module').then(
                        (m) => m.CompaniesModule
                    ),
            },
            {
                path: 'professional-profiles',
                loadChildren: () =>
                    import(
                        'app/modules/professional-profiles/professional-profiles.module'
                    ).then((m) => m.ProfessionalProfilesModule),
            },
            {
                path: 'courses',
                loadChildren: () =>
                    import('app/modules/courses/courses.module').then(
                        (m) => m.CoursesModule
                    ),
            },
            {
                path: 'competences',
                loadChildren: () =>
                    import('app/modules/competences/competences.module').then(
                        (m) => m.CompetencesModule
                    ),
            },
            {
                path: 'assessments',
                loadChildren: () =>
                    import('app/modules/assessments/assessments.module').then(
                        (m) => m.AssessmentModule
                    ),
            },
            {
                path: 'contact-forms',
                loadChildren: () =>
                    import('app/modules/contact-form/contact-form.module').then(
                        (m) => m.ContactFormModule
                    ),
            },
            {
                path: 'objectives',
                loadChildren: () =>
                    import('app/modules/objectives/objectives.module').then(
                        (m) => m.ObjectivesModule
                    ),
            },

            // faqs path
            {
                path: 'faqs',
                loadChildren: () =>
                    import('app/modules/faqs/faqs.module').then(
                        (m) => m.FaqsModule
                    ),
            },

            {
                path: 'file-manager',
                loadChildren: () =>
                    import('app/modules/file-manager/file-manager.module').then(
                        (m) => m.FileManagerModule
                    ),
            },
            {
                path: 'data-loader',
                loadChildren: () =>
                    import(
                        'app/modules/data-uploader/data-uploader.module'
                    ).then((m) => m.DataUploaderModule),
            },

            {
                path: 'helper-center',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                'app/modules/helper-center/help-center.module'
                            ).then((m) => m.HelpCenterModule),
                    },
                ],
            },
        ],
    },
];
