
<input type="file" class="file-input"
[accept]="requiredFileType" 
(change)="onFileSelected($event)" #fileUpload>

<div class="file-upload">

{{fileName || "Seleccionar imagen."}}

<button mat-mini-fab color="primary" [hidden] class="upload-btn" (click)="fileUpload.click()">
 <mat-icon>attach_file</mat-icon>

</button>

</div>

<div class="progress mt-2">
<mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress"> 

</mat-progress-bar>
    


  
</div>  
