import { Time } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Breadcrumb, BreadcrumbDetail } from "../models/breadcrumb";

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {

    private localStorageService;
    private breadCrumb: Breadcrumb = new Breadcrumb();

    constructor(private router: Router) {
        this.localStorageService = localStorage;
    }

    setBreadCrumb(breadcrumb: Breadcrumb): void {
      this.breadCrumb = breadcrumb;
      this.localStorageService.setItem('breadcrumb', JSON.stringify(breadcrumb));
    }
    setBreadCrumbUrl(ruta: string, nombre: string): void {
      this.breadCrumb.ruta = [];
      this.breadCrumb.ruta.push(new BreadcrumbDetail(ruta, nombre))
      this.localStorageService.setItem('breadcrumb', JSON.stringify(this.breadCrumb));
    }

    addBreadCrumb(ruta: string, nombre: string): void {
      this.breadCrumb.ruta.push(new BreadcrumbDetail(ruta, nombre))
      this.localStorageService.setItem('breadcrumb', JSON.stringify(this.breadCrumb));
    }

    getBreadCrumb(): Breadcrumb{
      return this.breadCrumb;
    }
    getBreadCrumbActive(): string{
      return this.breadCrumb.ruta[this.breadCrumb.ruta.length - 1].ruta;
    }
    getBreadCrumbGroupActive(group: string): boolean{

      for(let i:number =0; i < this.breadCrumb.ruta.length; i++){
        if(this.breadCrumb.ruta[i].ruta == group){
          return true;
        }
      }
      return false;
    }

}
