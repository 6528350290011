<div class="flex flex-col flex-auto p-6 shadow rounded-2xl overflow-hidden" [style.background-color]="bgColor">
    <div class="flex items-start justify-between">
        <div class="text-lg font-medium tracking-tight leading-6 truncate" [style.color]="titleColor">{{title}}</div>
        <div class="ml-2 -mt-2 -mr-3" *ngIf="addressing_route">
            <button
                mat-icon-button
                [matMenuTriggerFor]="summaryMenu">
                <mat-icon
                    class="icon-size-5 {{ classButtonColor }}"
                    [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
            </button>
            <mat-menu #summaryMenu="matMenu">
                <button mat-menu-item [routerLink]="[addressing_route]" [style.color]="addressingNameColor">Ir a {{addressing_name}}</button>



            </mat-menu>
        </div>
    </div>
    <div class="flex flex-col items-left mt-2">
        <ng-container *ngFor="let item of listItems">
            <div class="text-md font-medium truncate" [style.color]="activeNameColor">{{item.name}}</div>

        </ng-container>

    </div>
</div>
