import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-group-dashboard',
  templateUrl: './block-group-dashboard.component.html',
  styleUrls: ['./block-group-dashboard.component.scss']
})
export class BlockGroupDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
