export class Breadcrumb {
    ruta: Array<BreadcrumbDetail> = [];
    nombre: Array<string> = [];

    constructor(){
      this.ruta = [];
      this.nombre = [];
    }
  }
  
  export class BreadcrumbDetail{
    ruta: string;
    nombre: string;
  
    constructor(ruta: string, nombre:string){
      this.ruta = ruta;
      this.nombre = nombre;
    }
  }
  