<div #chartContainer class="flex-auto">
<apx-chart #mainChart
    class="flex flex-col flex-auto p-6 shadow rounded-2xl overflow-hidden bg-white"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [stroke]="chartOptions.stroke"
    [dataLabels]="chartOptions.dataLabels"
    [yaxis]="chartOptions.yaxis"
    [labels]="chartOptions.labels"
    [legend]="chartOptions.legend"
    [title]="chartOptions.title"
    [subtitle]="chartOptions.subtitle"
    [tooltip]="chartOptions.tooltip"
    [markers]="chartOptions.markers"
    [colors]="chartOptions.colors"
></apx-chart>
</div>
