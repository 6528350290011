import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { AuthenticationService } from 'app/services/authentication.service';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from '../../../../../environments/environment';

declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() inputSideNav?: MatSidenav;
  titulo : String;

  public focus: any;
  public listTitles: any[] = [];
  public location: Location;
  hoverNotification: boolean = false;
  hoverMenu: boolean = false;
  existPreview:boolean = false;

  constructor(  location: Location,  private element: ElementRef, private router: Router,
    public authenticationService: AuthenticationService) {
      this.location = location;
    this.titulo = "Dashboard";


  }


  get notificationsCount(){
    if(this.authenticationService.currentUserValue?.NotificationsCount == 0){
        return null;
    }
    return this.authenticationService.currentUserValue?.NotificationsCount;
  }

  get nameUser(){
    return this.authenticationService.currentUserValue?.name;
  }

  ngOnInit() {
  }



  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }

    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
            return this.listTitles[item].title;
        }
    }
    return 'Dashboard';
  }

  public logout(): void{

    this.authenticationService.logout();

    window.location.reload();

  }



}
