import {  TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService, TRANSLOCO_TRANSPILER } from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { Translation } from '@ngneat/transloco/lib/types';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

@NgModule({
    imports: [
        TranslocoMessageFormatModule.forRoot()
    ],
    exports  : [
        TranslocoModule
    ],
    providers: [
        {
            // Provide the default Transloco configuration
            provide : TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs      : [
                    {
                        id   : 'es',
                        label: 'Español'
                    },
                    {
                        id   : 'ca',
                        label: 'Català'
                    }
                ],
                defaultLang         : 'es',
                fallbackLang        : 'es',
                reRenderOnLangChange: true,
                prodMode            : false
            })
        },
        {
            // Provide the default Transloco loader
            provide : TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide   : APP_INITIALIZER,
            deps      : [TranslocoService],
            useFactory: (translocoService: TranslocoService): any => (): Promise<Translation> => {
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);
                return translocoService.load(defaultLang).toPromise();
            },
            multi     : true
        },/*{
            provide: TRANSLOCO_TRANSPILER, useClass: TranslocoMessageFormatModule
        }*/
    ]
})
export class TranslocoCoreModule
{
}
