
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';

@Injectable()
export class MatPaginatorCustom extends MatPaginatorIntl {

  filtro: number;

  constructor() {
    super();

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {

      this.itemsPerPageLabel = "Items por página";
      this.nextPageLabel = "Siguiente página";
      this.previousPageLabel = "Página anterior";
      this.lastPageLabel = "Última página";
      this.firstPageLabel = "Primera página";

      this.changes.next();

  }

 getRangeLabel = (page: number, pageSize: number, length: number, filtro?: number) =>  {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    if(filtro){
      return `${startIndex + 1} - ${endIndex} / ${filtro} de un total de ${length}}`;
    }else {
      return `${startIndex + 1} - ${endIndex} de ${length}`;

    }
  }
}
