<mat-toolbar color="{{ data.ClassType}}"  class="bg-gradient-custom">
<!-- <mat-toolbar color="{{ data.ClassType}}"  class="bg-{{ data.ClassType}}"></mat-toolbar> -->
  <mat-toolbar-row>
    <span>{{ data.Title }}</span>
    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="cancel()" >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  </mat-toolbar>

<!-- <h2 mat-dialog-title class="bg-{{ data.ClassType}}">{{ data.Title }}</h2> -->
<div>

  <div class="container">

    <div  class="row">
      <div [innerHTML]="data.Body" class="col-12">
      </div>
    </div>


  <div class="row">
    <div class="col-6">

      <button class="btn btn-secondary btn-block my-4 w-100 mb-0" type="submit" (click)="cancel()" >
        {{ data.BtnCancel }}
      </button>

    </div>
    <div class="col-6">
      <button class="btn btn-primary btn-block my-4 w-100 mb-0" type="submit" (click)="accept()" >
        {{ data.BtnAccept }}
      </button>


    </div>

  </div>


</div>

</div>
