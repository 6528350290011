import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from 'app/core/user/user.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { User } from 'app/models/user';
import { environment } from '../../../../../environments/environment';
import { FuseConfirmationConfig, FuseConfirmationService } from '@fuse/services/confirmation';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Company } from 'app/models/companies';
import { UsersService } from 'app/services/users.service';
import { UtilsService } from 'app/services/utils.service';

@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    loading: boolean = true;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: UntypedFormBuilder,
        private _authSrv: AuthenticationService,
        private _usersService: UsersService,
        private _utils: UtilsService,

    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    get showLang(): boolean
    {
        return environment.showLang;
    }
    get showSearch(): boolean
    {
        return environment.showSearch;
    }
    get showShortcuts(): boolean
    {
        return environment.showShortcuts;
    }
    get showMessages(): boolean
    {
        return environment.showMessages;
    }
    get showLogout(): boolean
    {
        return environment.showLogout;
    }
    get showNotifications(): boolean
    {
        return environment.showNotifications;
    }
    get showProfile(): boolean
    {
        return environment.showProfile;
    }

    get isPro(): boolean
    {
        return environment.production;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.loading = false;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    signOut(): void
    {

        let modal: FuseConfirmationConfig = {
            "title": "Cerrar sesión",
            "message": "Desea cerrar la sesión?",
            "icon": {
              "show": true,
              "name": "heroicons_outline:exclamation",
              "color": "warn"
            },
            "actions": {
              "confirm": {
                "show": true,
                "label": "Cerrar sesión",
                "color": "warn"
              },
              "cancel": {
                "show": true,
                "label": "Cancelar"
              }
            },
            "dismissible": true
          };

        const dialogRef = this._fuseConfirmationService.open( modal );

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result);
            if(result == "confirmed"){
                this._router.navigate(['/sign-out']);
            }
        });

        //this._router.navigate(['/sign-out']);

    }

    changeCompany(){
        console.log(this.user.active_company.id);
        this.user.active_company = new Company( <Company>this.user.companies.find( x => x.id == this.user.active_company.id ));
        this.user.company_active_id = this.user.active_company.id;
        this._authSrv.updateActiveCompany(this.user.active_company);
        this.setMainCompany(this.user.active_company);
    }

    setMainCompany(company: Company){

        this._usersService.setMain(company).subscribe({
            next: (result: User) => {

                let usr: User = <User> new User(result);
                this._authSrv.updateUser(usr);

            },
            error: (err: any) => {

                const dialogRef = this._fuseConfirmationService.open(this._utils.alert_error("Problemas al marcar la empresa", "Error al marcar la empresa."));

                dialogRef.afterClosed().subscribe((result) => {
                    console.log(result);
                });

            }
        });
    }
}
