import { Component, Inject, OnInit } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-part-dashboard',
  templateUrl: './part-dashboard.component.html',
  styleUrls: ['./part-dashboard.component.scss']
})
export class PartDashboardComponent implements OnInit{
    // Text related
    @Input() title: string;
    @Input() type_name: string;
    @Input() type_number: number;
    @Input() active_name: string;
    @Input() active_number: number;
    @Input() addressing_name: string;
    @Input() addressing_route: string;
    // Clor related.
    @Input() bgColor: string;
    @Input() titleColor: string = '#01949a';
    @Input() typeNameColor: string = '#01949a';
    @Input() typeNumberColor: string = '#01949a';
    @Input() activeNameColor: string = '#01949a';
    @Input() activeNumberColor: string = '#01949a';
    @Input() addressingNameColor: string = '#01949a';
    @Input() classButtonColor: string = '';

    // [title]='' [type_name]='' [type_number] ='' [active_name] ='' [active_number] ='' [addressing_name] ='' [addressing_route] =''
    // [bgColor] ='white' [titleColor]='white' [typeNameColor]='white' [typeNumberColor]='white' [activeNameColor]='white' [activeNumberColor]='white' [addressingNameColor]='white'


    constructor() {}
    ngOnInit(): void {

    }


}
