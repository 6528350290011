import { User } from "./user";

export class Company {

    public id?: number;
    public name?: string;
    public intern_name?: string;
    public intern_description?: string;
    public slug?: string;
    public status?: string;

    public active?: boolean = false;
    public logo?: string;
    public color?: string;

    public limit_users?: number;
    public limit_professional_profiles?: number;
    public limit_assessments?: number;
    public limit_assessments_month?: number;

    public created_at?: Date;
    public updated_at?: Date;

    public users?: User[];
    public total_users?: number;
    public organization_charts?: OrganizationCharts[];

    public total_assessments?: number;
    public total_assessments_canceled?: number;
    public total_assessments_completed?: number;

    public total_assessments_canceled_co?: number;
    public total_assessments_canceled_auto?: number;
    public total_assessments_co?: number;
    public total_assessments_auto?: number;
    public total_assessments_completed_co?: number;
    public total_assessments_completed_auto?: number;

    public goals_total_sum?: number;
    public goals_total_count?: number;
    public goals_total_done_count?: number;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}

export class OrganizationCharts {
    id?:          number;
    name?:        string;
    description?: null;
    order?:       number;
    id_company?:  number;
    created_at?:  Date;
    updated_at?:  null;
    positions?:   OrganizationChartsPosition[];

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}

export class OrganizationChartsPosition {
    id?:                    number;
    name?:                  string;
    name_parent?:           string;
    parent?:                OrganizationChartsPosition;
    children?:              OrganizationChartsPosition[];
    description?:           null;
    id_parent_position?:    number | null;
    order?:                 number;
    active?:                boolean;
    id_organization_chart?: number;
    created_at?:            Date;
    updated_at?:            null;
    id_organization_chart_positions_department?: number;
    department?: OrganizationChartsPositionDepartment;

    users?:                 User[];

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}

export class OrganizationChartsPositionDepartment {
    id?:                    number;
    name?:                  string;
    description?:           null;
    order?:                 number;
    is_active?:                boolean;
    created_at?:            Date;
    updated_at?:            null;

    id_organization_chart?: number;
    organization_chart?: OrganizationCharts;

    positions?:                 OrganizationChartsPosition[];

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}
