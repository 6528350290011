<div id="side-nav-shared" class="sidenav sidebar-wrapper">

    <div class="sidebar-content ">

      <div class="sidebar-menu">
        <div class="user-info">
          <div class="name color-text">{{ nameUser }}</div>
          <div class="rol color-text">{{ nameRol }}</div>
          <!-- <div *ngIf="userAuth" class="name color-text">{{ userAuth.nombre??'' }}</div>
          <div *ngIf="userAuth && userAuth.rol " class="rol color-text">{{ userAuth.rol!.nombre??'' }}</div>-->
        </div>
        <div class="divider"></div>

        <ul>
          <li class="header-menu">
            <span>General</span>
          </li>

          <li id="menu-home" class="sidebar-dropdown" (mouseover)="hoverCss('menu-home')" (mouseleave)="leaveCss('menu-home')">
            <a [routerLink]="['/home']" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}" >
              <i class="fa fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>

          <li  class="sidebar-dropdown" id="menu-bell"  (mouseover)="hoverCss('menu-bell')" (mouseleave)="leaveCss('menu-bell')">
            <a [routerLink]="['/notifications/list']" routerLinkActive="active">
              <i class="fas fa-bell"></i>
              <span>Notificaciones</span>
            </a>
          </li>

          <li  class="sidebar-dropdown" id="menu-account"  (mouseover)="hoverCss('menu-account')" (mouseleave)="leaveCss('menu-account')">
            <a [routerLink]="['/account']" routerLinkActive="active">
              <i class="fas fa-user-shield"></i>
              <span>Cuenta</span>
            </a>
          </li>


         <!-- <li class="sidebar-dropdown">
            <a routerLink="/viaje2/1">
              <i class="fa fa-tachometer-alt"></i>
              <span>Test</span>
            </a>
          </li> -->

          <li class="d-md-inline d-lg-none" (click)="logout()">
            <a href="#" >
              <i class="fas fa-sign-out-alt"></i>
              <span>Salir</span>
            </a>
          </li>


  <!--

          <li class="sidebar-dropdown">
            <a href="#">
              <i class="fa fa-shopping-cart"></i>
              <span>E-commerce</span>
              <span class="badge badge-pill badge-danger">3</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li>
                  <a href="#">Products

                  </a>
                </li>
                <li>
                  <a href="#">Orders</a>
                </li>
                <li>
                  <a href="#">Credit cart</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="sidebar-dropdown">
            <a href="#">
              <i class="far fa-gem"></i>
              <span>Components</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li>
                  <a href="#">General</a>
                </li>
                <li>
                  <a href="#">Panels</a>
                </li>
                <li>
                  <a href="#">Tables</a>
                </li>
                <li>
                  <a href="#">Icons</a>
                </li>
                <li>
                  <a href="#">Forms</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="sidebar-dropdown">
            <a href="#">
              <i class="fa fa-chart-line"></i>
              <span>Charts</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li>
                  <a href="#">Pie chart</a>
                </li>
                <li>
                  <a href="#">Line chart</a>
                </li>
                <li>
                  <a href="#">Bar chart</a>
                </li>
                <li>
                  <a href="#">Histogram</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="sidebar-dropdown">
            <a href="#">
              <i class="fa fa-globe"></i>
              <span>Maps</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li>
                  <a href="#">Google maps</a>
                </li>
                <li>
                  <a href="#">Open street map</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="header-menu">
            <span>Extra</span>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-book"></i>
              <span>Documentation</span>
              <span class="badge badge-pill badge-primary">Beta</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-calendar"></i>
              <span>Calendar</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-folder"></i>
              <span>Examples</span>
            </a>
          </li> -->
        </ul>
      </div>
    </div>

    <!--
    <ul  class="nav list-unstyled">
      <li class="active">
        <a routerLink="/home" ><i class="fas fa-tachometer-alt"></i> Dashboard</a>
      </li>
      <li class="d-md-inline d-lg-none"  (click)="logout()">
        <a href=""><i class="fas fa-sign-out-alt"></i> Salir </a>

      </li>
    </ul> -->

    <div class="sidebar-content-footer">
      <div class="sidebar-footer">
        <a  id="menu-bottom-1" [routerLink]="['/notifications/list']" routerLinkActive="active"  (mouseover)="hoverBottomCss('menu-bottom-1')" (mouseleave)="leaveBottomCss('menu-bottom-1')">
          <i class="fa fa-bell"></i>
        </a>

        <a  id="menu-bottom-3" [routerLink]="['/account']" routerLinkActive="active" (mouseover)="hoverBottomCss('menu-bottom-3')" (mouseleave)="leaveBottomCss('menu-bottom-3')">
          <i class="fa fa-cog"></i>
        </a>
        <div (click)="logout()" id="menu-bottom-4"  (mouseover)="hoverBottomCss('menu-bottom-4')" (mouseleave)="leaveBottomCss('menu-bottom-4')">
          <i class="fa fa-power-off"></i>
        </div>
      </div>
    </div>

  </div>
