<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img
                    class="w-8"
                    src="assets/images/logo/logo.png">
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <notifications *ngIf="showNotifications"></notifications>
                <user *ngIf="showProfile" [showAvatar]="false"></user>

                <button *ngIf="showLogout"
                    mat-icon-button
                    (click)="signOut()">
                    <mat-icon [svgIcon]="'heroicons_outline:logout'" class="text-white"></mat-icon>
                </button>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4" *ngIf="user">
            <div class="relative w-24 h-24">
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="user.avatar"
                    [src]="user.avatar"
                    alt="User avatar">
                <mat-icon
                    class="icon-size-24 text-white"
                    *ngIf="!user.avatar"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.name}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-hr-200">
                    {{user.email}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-[11px] leading-normal font-medium">
                    {{ user.role.name }}
                </div>


                <div *ngIf="user.role.tag == 'professional-freelance'" class="mt-3 w-full whitespace-nowrap text-ellipsis overflow-hidden text-center text-[11px] leading-normal font-medium">
                    Empresa activa:
                </div>
                <div *ngIf="user.role.tag == 'professional-freelance'" class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium  text-hr-200">
                    {{ user.active_company.name }}
                </div>

            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mb-4 mt-10">
            <img
                class="max-w-32"
                src="assets/images/logo/Logo-Executive-2.png">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->


        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <div class=" hidden sm:flex" *ngIf="user.role.tag == 'professional-freelance' && loading == false">
                Empresa seleccionada:
            </div>
            <div class="flex flex-col gt-xs:flex-row min-w-40 items-center justify-center mt-5"  *ngIf="user.role.tag == 'professional-freelance' && loading == false"  >

                    <mat-form-field
                        class="flex-auto gt-xs:pl-3">
                        <mat-select [(ngModel)]="user.active_company.id" class="min-w-64" (ngModelChange)="changeCompany()"  >
                            <ng-container *ngFor="let company of user.companies">
                                <mat-option [value]="company.id">{{ company.name }}</mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-icon
                            class="icon-size-5"
                            matSuffix
                            [svgIcon]="'arrow_drop_down'"></mat-icon>
                    </mat-form-field>

            </div>
            <languages *ngIf="showLang"></languages>
            <div *ngIf="!isPro" class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
                <div class=" hidden sm:flex" >
                    Entorno de pruebas
                </div>
            </div>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'" *ngIf="showSearch"></search>
            <shortcuts *ngIf="showShortcuts"></shortcuts>
            <messages *ngIf="showMessages"></messages>

            <!--            <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>-->

        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->

</div>

<!-- Quick chat -->
<!--
<quick-chat #quickChat="quickChat"></quick-chat>
-->
