import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation';
import { FuseConfirmationConfigCustom } from 'app/models/fuseConfirmationConfigCustom';
import { Modal } from 'app/models/modal';
import { CompaniesModule } from '../../modules/companies/companies.module';
import { AddCompanyPositionComponent } from '../../modules/companies/components/add-company-position/add-company-position.component';
import { OrganizationChartsPosition } from '../../models/companies';
import { environment } from '../../../environments/environment.pre';
import { User } from 'app/models/user';


@Component({

    selector: 'app-organization-chart-position-add-quick',
    templateUrl: './organization-chart-position-add-quick.component.html',
    styleUrls: ['./organization-chart-position-add-quick.component.scss'],
    styles: [
        `
      .fuse-confirmation-dialog-panel {

          @screen md {
              @apply w-128;
          }

          .mat-mdc-dialog-container {

              .mat-mdc-dialog-surface {
                  padding: 0 !important;
              }
          }
      }
  `
    ],
    encapsulation: ViewEncapsulation.None
})
export class OrganizationChartPositionAddQuickComponent {


    inputValue: string = '';
    parentPosition: number = -1;
    department: string = '';
    data: FuseConfirmationConfigCustom;

    inputForm: UntypedFormGroup;
    currentPosition?: OrganizationChartsPosition;
    currentUser?: number;
    initCurrentUser?: number;

    showChangeParent: boolean = true;

    positions: OrganizationChartsPosition[];
    users?: User[];
    btn_text?: string;

    constructor(
        private dialogRef: MatDialogRef<OrganizationChartPositionAddQuickComponent>,
        @Inject(MAT_DIALOG_DATA) public dataInput: FuseConfirmationConfigCustom,
        private _formBuilder: UntypedFormBuilder,
    ) {
        console.log(this.dataInput);

        if (dataInput) {
            this.data = dataInput;

            if(this.data.other && this.data.other.btn_text){
                this.btn_text = this.data.other.btn_text;
            }

            if(this.data.other && this.data.other.department){
                this.department = this.data.other.department;
            }

            if(this.data.other && this.data.other.id){

                if(this.data.other.positions){
                    console.log(this.data.other.id);
                    this.positions = this.data.other.positions.filter( (x:OrganizationChartsPosition)=> { return x.name != environment.nameDefaultPosition});

                    this.currentPosition = this.positions.find( (x:OrganizationChartsPosition)=> { return x.id == this.data.other.id});
                    console.log(this.currentPosition);
                    this.inputValue = this.currentPosition.name
                    this.parentPosition = (this.currentPosition.parent != null )?this.currentPosition.parent.id:-1;
                    if(this.parentPosition == -1){
                        this.showChangeParent = false;
                    }

                    if(this.currentPosition.department){
                        this.department = this.currentPosition.department.name;
                    }


                }else if(this.data.other.position && this.data.other.users){
                    this.users = [ ...this.data.other.users ];
                    this.positions = [ this.data.other.position ];
                    this.currentPosition = this.data.other.position.id;
                    this.inputValue = this.data.other.position.name
                    this.parentPosition = (this.currentPosition.parent != null )?this.currentPosition.parent.id:-1;
                    if(this.parentPosition == -1){
                        this.showChangeParent = false;
                    }

                    this.currentUser = -1;
                    if(this.data.other.id_user){
                        this.currentUser = this.data.other.id_user
                    }
                    this.initCurrentUser = this.currentUser;
                }
            }else{
                this.positions = this.data.other.filter( (x:OrganizationChartsPosition)=> { return x.name != environment.nameDefaultPosition});
                this.parentPosition = this.positions.length > 0?this.positions[this.positions.length - 1].id:-1;
            }
        }


        this.inputForm = this._formBuilder.group({
            name: [this.inputValue, Validators.required],
            position: [this.parentPosition, Validators.required],
            department: [this.department, Validators.required],
            user: [this.currentUser, (this.currentUser)?Validators.required:Validators.nullValidator]
        });

        console.log(this.inputForm.get('position').value);
        console.log(this.inputForm.get('user').value);
    }

    get validate(): boolean {

        if (this.inputValue == "" || this.inputForm.invalid) {
            return false;
        }

        return true;
    }



}
