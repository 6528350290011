/* eslint-disable */
export const faqCategories = [
    {
        id   : '28924eab-97cc-465a-ba21-f232bb95843f',
        slug : 'most-asked',
        title: 'Más frequentes'
    },

];
export const faqs = [
    // Most asked
    {
        id        : 'f65d517a-6f69-4c88-81f5-416f47405ce1',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question  : '¿Qué es una evaluación 360° de Executive Leadership HR?',
        answer    : 'Las evaluaciones 360° son una herramienta para recibir retroalimentación de manera completa, por todas las personas o una muestra importante con las que se tiene interacción. Esto quiere decir que se contará con retroalimentación integral ya que me pueden evaluar mi jefe(a), mis compañeros(as) de trabajo, clientes internos o externos, personal a cargo (si aplica), incluso me evalúo yo mismo(a). Con ello se consigue dotar de mayor credibilidad a la evaluación.'
    },
    {
        id        : 'f65d517a-6f69-4c88-81f5-416f47405ce1',
        categoryId: '28924eab-97cc-465a-ba21-f232bb95843f',
        question  : '¿Por qué es importante hacer una evaluación 360° de Executive Leadership HR?',
        answer    : 'Permite recibir retroalimentación objetiva sobre mis fortalezas y debilidades, generando planes de acción y desarrollo enfocados en cerrar brechas.\nLa evaluación 360° va a ser clave para tu desarrollo, ya que nos va a permitir trazar una línea base y medir el progreso dentro del programa, ya que vas a realizar 3 evaluaciones durante los 10 meses de duración del programa: al inicio, en la mitad y al cierre.\nLas evaluaciones 360° se caracterizan por ser anónimas, incluir una muestra de evaluadores clave con los que se tiene más interacción y están enfocadas a evaluar temas específicos. Estas características hacen que la información esté libre de sesgos y sea de alta calidad para trabajar sobre brechas de desarrollo profesional.'
    },
];
export const guideCategories = [
    {
        id   : '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug : 'getting-started',
        title: 'Getting Started'
    },
    {
        id   : '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug : 'projects',
        title: 'Projects'
    },
    {
        id   : 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug : 'settings',
        title: 'Settings'
    },
    {
        id   : '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug : 'payments',
        title: 'Payments'
    },
    {
        id   : '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug : 'your-account',
        title: 'Your Account'
    }
];
export const guides = [
    // Getting started
    {
        id        : 'a008ffa3-7b3f-43be-8a8f-dbf5272ed2dd',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug      : 'what-is-this-app',
        title     : 'What is this app?',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '7643d388-12ab-4025-a2f1-5045ac7b1c4c',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug      : 'start-using-the-app',
        title     : 'Start using the app',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '1fecee67-c4b4-413a-b0f2-949dcab73249',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug      : 'signing-in-to-the-dashboard',
        title     : 'Signing in to the dashboard',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : 'd2e2ea8f-5298-4ba2-898b-afc60c064bba',
        categoryId: '0ee72de7-49c0-4880-9e89-b72a4edd6a81',
        slug      : 'navigating-within-the-app',
        title     : 'Navigating within the app',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    // Projects
    {
        id        : 'f2592886-11b8-4b56-baab-96802c2ed93e',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'creating-a-project',
        title     : 'Creating a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '9ec3f4b9-a355-4f57-9e93-efa8611cc1c9',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'renaming-a-project',
        title     : 'Renaming a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '1bc6e7f9-b046-4f4f-9b18-741c9d5429f6',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'displaying-a-project',
        title     : 'Displaying a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : 'a005d5f1-938d-45c5-8ed4-d0cf8d02e533',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'deleting-a-project',
        title     : 'Deleting a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '43837279-dce2-4dc0-beac-30b5ba829f14',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'changing-the-visibility-of-a-project',
        title     : 'Changing the visibility of a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '4cf5a435-eaa0-463c-8d2b-efde193c7fb3',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'adding-media-to-a-project',
        title     : 'Adding media to a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : 'cd3fb87e-e138-4721-9e29-a5c751bfd949',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'removing-a-media-from-a-project',
        title     : 'Removing a media from a project',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : 'f26205c6-882e-4713-b067-c73758b45551',
        categoryId: '07b8421f-20bf-45b6-90ee-169ebe3a5bcc',
        slug      : 'cropping-a-media',
        title     : 'Cropping a media',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    // Settings
    {
        id        : '1cbdeaeb-bbf1-4d04-b43d-f37b55e6a229',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug      : 'general-settings',
        title     : 'General settings',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '98de7d4a-2ca2-4d47-bbe6-083ed26467db',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug      : 'project-settings',
        title     : 'Project settings',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '145f497c-1fdb-47b5-a6c1-31f856403571',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug      : 'media-settings',
        title     : 'Media settings',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '0a007f59-a5ea-4875-991d-f22d6fd69898',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug      : 'domain-settings',
        title     : 'Domain settings',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '4707c8eb-31f9-415c-bd07-86f226c75feb',
        categoryId: 'c88a1f54-360a-4b9b-a54b-2f92b7a1f63b',
        slug      : 'privacy-settings',
        title     : 'Privacy settings',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    // Payments
    {
        id        : 'c771bf0a-1e0c-4b6d-af7e-189e10cc6fb8',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug      : 'subscriptions',
        title     : 'Subscriptions',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '3d7150d2-feb3-4f20-bd3f-8e525cef77a4',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug      : 'discounts',
        title     : 'Discounts',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '79239bc4-4fb5-428b-b30d-62c5289b061d',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug      : 'payment-methods',
        title     : 'Payment methods',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '8d68c5e6-5404-450c-9d5f-d9800c164041',
        categoryId: '7b25b38c-1ab3-4474-8569-65b3ea232add',
        slug      : 'overdue-payments',
        title     : 'Overdue payments',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    // Your account
    {
        id        : '60df0d4c-dda1-439c-bd44-179c57a7597d',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'changing-your-username',
        title     : 'Changing your username',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '0a9c3321-1db3-42bc-92b6-7e257368123e',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'changing-your-email',
        title     : 'Changing your email',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '80ba5106-5f9c-4ed7-b8f3-8544035e3095',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'changing-your-password',
        title     : 'Changing your password',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : 'db2e97a6-d657-4e9d-9b6c-5f213ea3301c',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'closing-your-account',
        title     : 'Closing your account',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : '3374c887-2fb7-4223-9f40-7f2cbbf76795',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'account-limits',
        title     : 'Account limits',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    },
    {
        id        : 'cc65f92a-7d46-4557-b15b-6f8f59a60576',
        categoryId: '41fdf071-aec4-49de-9dd4-b4f746596928',
        slug      : 'two-factor-authentication',
        title     : 'Two factor authentication',
        subtitle  : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
    }
];

// Since we only have one content for the demo, we will
// use the following mock-api on every request for every guide.
export const guideContent = `
<h2>Header Level 2</h2>

<p>
    <strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit
    amet, ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper
    pharetra. Vestibulum erat wisi, condimentum sed, <code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci,
    sagittis tempus lacus enim ac dui. <a href="#"
                                          class="link">Donec non enim</a>
    in turpis pulvinar facilisis. Ut felis.
</p>

<p>
    Orci varius natoque penatibus et magnis dis <em>parturient montes</em>, nascetur ridiculus mus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
    himenaeos. Curabitur vitae sagittis odio. <mark>Suspendisse</mark> ullamcorper nunc non pellentesque laoreet. Curabitur eu tortor id quam pretium mattis. Proin ut quam velit.
</p>

<h3>Header Level 3</h3>

<img src="assets/images/pages/help-center/image-1.jpg">
<p class="text-secondary">
    <em>Nullam sagittis nulla in diam finibus, sed pharetra velit vestibulum. Suspendisse euismod in urna eu posuere.</em>
</p>

<h4>Header Level 4</h4>

<blockquote>
    <p>
        Blockquote. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur
        massa. Donec eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est.
    </p>
    <footer>
        Brian Hughes
    </footer>
</blockquote>

<ol>
    <li>Ordered list</li>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
</ol>

<h5>Header Level 5</h5>

<ul>
    <li>Unordered list</li>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
</ul>

<pre><code>#header h1 a {
    display: block;
    width: 300px;
    height: 80px;
}</code></pre>

<h6>Header Level 6</h6>

<dl>
    <dt>Definition list</dt>
    <dd>
        Quisque sit amet risus enim. Aliquam sit amet interdum justo, at ultricies sapien. Suspendisse et semper urna, in gravida eros. Quisque id nibh iaculis, euismod urna sed,
        egestas nisi. Donec eros metus, congue a imperdiet feugiat, sagittis nec ipsum. Quisque dapibus mollis felis non tristique.
    </dd>

    <dt>Definition list</dt>
    <dd>
        Ut auctor, metus sed dapibus tempus, urna diam auctor odio, in malesuada odio risus vitae nisi. Etiam blandit ante urna, vitae placerat massa mollis in. Duis nec urna ac
        purus semper dictum ut eget justo. Aenean non sagittis augue. Sed venenatis rhoncus enim eget ornare. Donec viverra sed felis at venenatis. Mauris aliquam fringilla nulla,
        sit amet congue felis dignissim at.
    </dd>
</dl>`;
