import { Component, OnInit, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { ChartData, ChartOptions } from 'app/models/chart';
import { ChartComponent } from 'ng-apexcharts';


@Component({
  selector: 'app-chronological-result-assessment-chart',
  templateUrl: './chronological-result-assessment-chart.component.html',
  styleUrls: ['./chronological-result-assessment-chart.component.scss']
})
export class ChronologicalResultAssessmentChartComponent implements OnInit, OnChanges {
    @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;
    @ViewChild('mainChart', { static: false }) mainChart: ChartComponent;

    // Text related
    @Input() title: string;
    @Input() subtitle: string;
    @Input() series_name: string;
    @Input() yaxis_title: string;


    // Color related.
    @Input() colors: string[] = ['#008FFB']; // blue

    // Data related.
    @Input() professional_profile_id: number = -1;
    @Input() assessmentsData: Map<number, ChartData> = new Map<number, ChartData>();

    data_series: number[];
    data_categories: Date[];

    uuid_array: string[];

    resizeObserver: ResizeObserver;

    public chartOptions: ChartOptions;

    constructor(
        private _router: Router,
    ) { }

    ngOnInit(): void {
        this.getChartData();
        this.initChart();
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.professional_profile_id || changes.assessmentsData) {
            this.getChartData();
            this.initChart();
        }
    }

    ngAfterViewInit() {
        this.resizeObserver = new ResizeObserver(() => this.updateChartSize());
        this.resizeObserver.observe(this.chartContainer.nativeElement);
    }

    updateChartSize() {
        setTimeout(() => {
            this.mainChart.updateOptions({
                chart: {
                    width: this.chartContainer.nativeElement.offsetWidth - 45,
                    // height: this.chartContainer.nativeElement.offsetHeight
                }
            });
        }, 10);
    }

    ngOnDestroy() {
        this.resizeObserver.unobserve(this.chartContainer.nativeElement);
    }


    getChartData(){
        if(this.assessmentsData.has(this.professional_profile_id)){
            this.data_series = this.assessmentsData.get(this.professional_profile_id).data_series[0] === undefined ? [] : this.assessmentsData.get(this.professional_profile_id).data_series;
            console.log('this.data_series ', this.data_series );
            this.data_categories = this.assessmentsData.get(this.professional_profile_id).data_categories[0] === undefined ? [] : this.assessmentsData.get(this.professional_profile_id).data_categories ;
            console.log('this.data_categories ', this.data_categories );
            this.uuid_array = this.assessmentsData.get(this.professional_profile_id).uuid_array[0] === undefined ? [] : this.assessmentsData.get(this.professional_profile_id).uuid_array ;
            console.log('this.uuid_array ', this.uuid_array );
        }else{
            this.data_series = [];
            this.data_categories = [];
            this.uuid_array = [];
        }
    }

    initChart(){
        this.chartOptions = {
            responsive: [
            {
                breakpoint: undefined,
            }],
            series: [{
                name: this.series_name,
                data: this.data_series
            }],
            chart: {
                type: 'area',
                height: 350,
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        console.log(event);
                        // Go to assessments detail page.
                        this._router.navigate(['assessments', this.uuid_array[config.dataPointIndex]] ) ;
                    }
                },
            },
            xaxis: {
                categories: this.data_categories,
            },
            yaxis: {
                title: {
                    text: this.yaxis_title
                },
            },
            colors: this.colors,
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight'
            },
            legend: {
                show: false,
            },
            title: {
                text: this.title,
                align: 'left'
            },
            subtitle: {
                text: this.subtitle,
                align: 'left'
            },
            labels: [],
            markers: {
                size: 5,
            },
            tooltip: {
                intersect: true,
                shared: false,
            }
        };
    }

}
