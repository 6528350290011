import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, filter, map } from 'rxjs/operators';
import { User } from '../models/user';
import { ApiRequestObject } from 'app/models/ApiRequestObject';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { Dataloader } from 'app/models/dataloader';
import { ListadoPaginado } from 'app/models/Contador';
import { UserParseLoader } from 'app/models/loader';

@Injectable({
    providedIn: 'root'
})
export class FilesService extends ApiService {


    constructor(private http: HttpClient, router: Router, authSrv: AuthenticationService) {
        super(router, authSrv);
    }

    upload(info: FormData){
        return this.http.post<any>(`${environment.baseUrl}files/upload`, info, {
        reportProgress: true,
        observe: 'events'
        }).pipe(
        catchError(this.error)
        );

    }

    uploadConfig(info: FormData, urlString: string =""){

        return this.http.post<any>(`${environment.baseUrl}files/${urlString}`, info, {
            reportProgress: true,
            observe: 'events'
        }).pipe(
            catchError(this.error)
        );
    }

    download(urlString: string =""): Observable<any>{

        return this.http.get<any>(`${environment.baseUrl}files/${urlString}`, {
            reportProgress: true,
            observe: 'body',
            responseType: 'text' as 'json'
        }).pipe(
            map((file: any) => {
                return file as ArrayBuffer;
            })
        );

    }

    dataloaderParse(urlString: string ="", data: Dataloader): Observable<UserParseLoader>
    {
        return this.http.post<ApiRequestObject>(`${environment.baseUrl}files/${urlString}`, data )
        .pipe(
            map((result: ApiRequestObject) =>  {
                console.log('result.result' , result.result);
                return result.result as UserParseLoader;

            } ),
            catchError(this.error)
        );

    }


    confirmParseData(urlString: string ="", data: Dataloader): Observable<UserParseLoader>
    {
        return this.http.post<ApiRequestObject>(`${environment.baseUrl}files/${urlString}`, data )
        .pipe(
            map((result: ApiRequestObject) => result.result as UserParseLoader),
            catchError(this.error)
        );

    }
}
