<ng-container *ngIf="info && withBlock == true">

  <div class="page w-100">

    <div class="group col-12 col-xl-12 col-lg-12">

      <app-block-dashboard>
        <h1>Parece que algo salió mal...</h1>
        <h3><strong>Code:</strong> {{ info.code??'-' }}</h3>

        <p *ngIf="info.code != 500 && info.code != 400">
          {{ info.msg }}
        </p>

        <p *ngIf="info.code == 500">
          Problemas para acceder al servidor, intentelo más tarde o pongase en contacto con el administrador.
        </p>

        <p *ngIf="info.code == 400">
          Problemas para acceder al servidor, intentelo más tarde o pongase en contacto con el administrador.
        </p>
        </app-block-dashboard>
      </div>
  </div>

</ng-container>

<ng-container *ngIf="info && withBlock == false">
  <p *ngIf="info.code != 500">
    {{ info.msg }}
  </p>

  <p *ngIf="info.code == 500">
    Problemas para acceder al servidor, inténtelo más tarde, si el problema persiste póngase en contacto con el administrador.
  </p>
  <p><strong>Code:</strong> {{ info.code??'-' }}</p>

</ng-container>
