<mat-toolbar color="{{ data.ClassType }}" class="bg-gradient-custom">
  <mat-toolbar-row>
    <span>{{ data.Title }}</span>
    <span class="example-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>
<div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        {{ data.Body }}
      </div>
    </div>
  </div>
</div>
