import { EmailVariable } from "./emailVariable";

export class Theme {
    Id?: number;
    IdUser?: number;

    CreatedAt?: Date;
    UpdatedAt?: Date;
    State?: number;
    Active?: boolean;

    HeadColor: string = "#212529";
    HeadIconColor: string = "#fff";
    HeadIconHoverColor= "#0d9c72";

    MenuColor  = "#31353d";
    MenuColorTop  = "#31353d";
    MenuColorBottom  = "#31353d";

    MenuTextColor  = "#ffffff";
    MenuTextActiveColor = "#0d9c72";
    MenuTextHoverColor = "#0d9c72";

    MenuBottomColor = "#3a3f48";
    MenuBottomTextColor  = "#fff";
    MenuBottomTextActiveColor = "#0d9c72";
    MenuBottomTextHoverColor = "#0d9c72";

    HeadLogoColor = "Images/default/brand.svg";

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

}
