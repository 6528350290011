import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-default-button',
  templateUrl: './default-button.component.html',
  styleUrls: ['./default-button.component.scss']
})
export class DefaultButtonComponent {

    @Input() disabled: boolean = false;
    @Input() is_loading: boolean = false;
    @Input() text: string = "";
    @Input() text_loading: string = "";
    @Input() type: number = 0;

}
