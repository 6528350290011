export const environment = {
    production: false,
    showLang: false,
    showSearch: false,
    showShortcuts: false,
    showMessages: false,
    showNotifications: false,
    showProfile: false,
    showLogout: true,
    showFileManager: true,
    showDataLoader: true,
    showFAQs: true,
    baseUrl: "https://apiexecutivehr.ricicle.com/v1/",
    baseUrlFront:"https://appexecutivehr.ricicle.com/",
    nameDefaultPosition: "Sin asignar",
    emailContact: "info@hrexecutive.com",
    levelAssessment: [
        { name: 'Bajo' , value: 65},
        { name: 'Suficiente' , value: 75},
        { name: 'Notable' , value: 85},
        { name: 'Alto' , value: 95},
        { name: 'Excelencia' , value: 95}
    ]
};
