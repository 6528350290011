import { AfterViewInit, Component, Directive, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Modal } from 'app/models/modal';
import { ConfirmationModalComponent } from 'app/modals/confirmation-modal/confirmation-modal.component';
import { UtilsService } from 'app/services/utils.service';
import { Theme } from 'app/models/theme';
import { User } from 'app/models/user';
declare const $: any


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, AfterViewInit {

  class: string = "#side-nav-shared";
  classTop: string = ".sidebar-content";
  classBottom: string = ".sidebar-content-footer";
  currentTheme : Theme = new Theme();

  userAuth: User | null = null;

  constructor(private router: Router, public authSrv: AuthenticationService, public dialog: MatDialog,
     private utilsSrv: UtilsService
    ) {



    }

  ngOnInit(): void {

    this.userAuth = this.authSrv.currentUserTo;

    console.log(this.userAuth);

  }


  get nameUser(){
    return this.authSrv.currentUserValue?.name??'';
  }

  get nameRol(){

    if(this.authSrv.currentUserValue?.role?.tag){
      if(this.authSrv.currentUserValue?.isAdmin){
        return 'Administrador';
      }
    }
    return this.authSrv.currentUserValue.role.name;
  }


  ngAfterViewInit(): void{
    console.log("ngAfterViewInit");
    //this.applyTheme();
    // QQ : En certs casos no es pinta el sidebar correctament
    setTimeout(() => {
      this.setTheme();
    }, 10);

  }

  public hoverCss(id: string){

    if(!$("#" + id).find("a").hasClass("active")){
      $("#" + id).find("i").css("color", this.currentTheme.MenuTextHoverColor);
      $("#" + id).find("span").css("color", this.currentTheme.MenuTextHoverColor);
    }
  }

  public leaveCss(id: string){
    if(!$("#" + id).find("a").hasClass("active")){

      $("#" + id).find("i").css("color", this.currentTheme.MenuTextColor);
      $("#" + id).find("span").css("color", this.currentTheme.MenuTextColor);
    }
  }

  public hoverBottomCss(id: string){

    if(!$("#" + id).find("a").hasClass("active")){
      $("#" + id).find("i").css("color", this.currentTheme.MenuBottomTextHoverColor);
      $("#" + id).find("span").css("color", this.currentTheme.MenuBottomTextHoverColor);
    }
  }

  public leaveBottomCss(id: string){
    if(!$("#" + id).find("a").hasClass("active")){

      $("#" + id).find("i").css("color", this.currentTheme.MenuBottomTextColor);
      $("#" + id).find("span").css("color", this.currentTheme.MenuBottomTextColor);
    }
  }

  public logout(): void{

    //window.location.reload();


    let modal = new Modal();
    modal.Title = "Cerrar sesión";
    modal.Body = "Seguro que quieres cerrar la sessión?";
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "400px",
        data: modal,
        closeOnNavigation: false,
        disableClose: true,
        panelClass: 'fuse-confirmation-dialog-panel',
        position: { top: '150px'}
      });
    dialogRef.afterClosed().subscribe( (result: boolean) => {
      console.log(`Dialog result: ${result}`);
      if(result == true){

        this.authSrv.logout();
      }
    });
  }

  private setTheme(){

    // console.warn('setTheme', this.currentTheme);

    let colorTop = this.utilsSrv.hexToRGB(this.currentTheme.MenuColorTop, 1);
    let color = this.utilsSrv.hexToRGB(this.currentTheme.MenuColor, 1);
    let colorBottom = this.utilsSrv.hexToRGB(this.currentTheme.MenuColorBottom, 1);

    console.log(colorTop, color, colorBottom);

    // Top
    $(this.class).css("background", "linear-gradient(180deg, " + colorTop + " 12%, " + color + " 65%, " + colorBottom + " 100%)" );
    $(this.classTop).find("a").css("color", this.currentTheme.MenuTextColor);
    $(this.classTop).find(".header-menu>span").css("color", this.currentTheme.MenuTextColor);
    $(this.classTop).find(".color-text").css("color", this.currentTheme.MenuTextColor);

    // Bottom
    console.log("color" , this.currentTheme.MenuBottomTextColor);
    $(this.classBottom).css("background", this.currentTheme.MenuBottomColor);
    $(this.classBottom).find("i").css("color", this.currentTheme.MenuBottomTextColor);
    $(this.classBottom).find("span").css("color", this.currentTheme.MenuBottomTextColor);

    // Active
    setTimeout( () => {
      $(this.classTop).find(".active").eq(0).find("i").css("color", this.currentTheme.MenuTextActiveColor);
      $(this.classTop).find(".active").eq(0).find("span").css("color", this.currentTheme.MenuTextActiveColor);

    }, 100 );
  }

}
