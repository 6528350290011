import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { ApiRequestObject, ListResult } from '../models/ApiRequestObject';
import { Role, User } from '../models/user';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { ListadoPaginado } from '../models/Contador';
import { UtilsService } from './utils.service';
import { FiltroViaje } from '../models/Coordenada';
import { DashboardStatistics } from 'app/models/dashboardStatistics';
import { Company } from 'app/models/companies';

@Injectable({
    providedIn: 'root'
})
export class UsersService extends ApiService {

    constructor(private http: HttpClient, router: Router, authSrv: AuthenticationService, private utilsSrv: UtilsService) {
        super(router, authSrv);
    }


    getRoles():Observable<Role[]> {
        return this.http.get<ApiRequestObject>(`${environment.baseUrl}users/roles`).pipe(
            map((result: ApiRequestObject) => result.result as Role[]),
            catchError(this.error)
        );
    }

    getList(page: number, offset: number, order: string, direction: string, filter?: string, company?: Company): Observable<ListadoPaginado> {

        if(!filter || ( filter && filter == "" )){
            filter = "";
        }else{
            filter = `?filter=${filter}`
        }

        if(company && filter == ""){
            filter = `?company_id=${company.id}`
        }else if(company && filter != ""){
            filter = `${filter}&company_id=${company.id}`
        }

        var url = `${environment.baseUrl}users/${page}/${offset}/${order}/${direction}${filter}`;
        console.log(url);
        return this.http.get<ApiRequestObject>(url).pipe(
            map( (resp:ApiRequestObject) => resp.result ),
            catchError(this.error)
        );

    }


    syncUsers() {


        return this.http.get<ListResult>(`${environment.baseUrl}users/usersRelatedSync`).pipe(
            catchError(this.error)
        );
    }


    signUp(user: User): Observable<ApiRequestObject> {
        return this.http.post<ApiRequestObject>(`${environment.baseUrl}sign-up`, user).pipe(
            catchError(this.error)
        );
    }

    forgotPassword(email: string): Observable<ApiRequestObject> {
        return this.http.post<ApiRequestObject>(`${environment.baseUrl}forgot-password`, { 'email':email}).pipe(
            catchError(this.error)
        );
    }

    changePassword(token: string, password: string): Observable<ApiRequestObject> {
        return this.http.post<ApiRequestObject>(`${environment.baseUrl}reset-password`, { 'token':token , 'password':password}).pipe(
            catchError(this.error)
        );
    }

    create(user: User): Observable<User> {

        return this.http.post<ApiRequestObject>(`${environment.baseUrl}users`, user).pipe(
            map( (resp:ApiRequestObject) => resp.result as User ),
            catchError(this.error)
        );
    }


    delete(id: number): Observable<boolean> {
        return this.http.delete<ApiRequestObject>(`${environment.baseUrl}users/${id}`).pipe(
            map( (resp:ApiRequestObject) => true ),
            catchError(this.error)
        );
    }

    changeStatus(id: number, active: boolean): Observable<boolean> {
        if(active){
            return this.active(id);
        }else{
            return this.disable(id);
        }
    }
    active(id: number): Observable<boolean> {
        return this.http.get<ApiRequestObject>(`${environment.baseUrl}users/${id}/active`).pipe(
            map( (resp:ApiRequestObject) => true ),
            catchError(this.error)
        );
    }

    disable(id: number): Observable<boolean> {
        return this.http.get<ApiRequestObject>(`${environment.baseUrl}users/${id}/disable`).pipe(
            map( (resp:ApiRequestObject) => true ),
            catchError(this.error)
        );
    }


    getDashboard(): Observable<DashboardStatistics> {
        return this.http.get<ApiRequestObject>(`${environment.baseUrl}dashboard`).pipe(
            map( (resp:ApiRequestObject) => resp.result as DashboardStatistics ),
            catchError(this.error)
        );
    }



    setMain(company: Company): Observable<User>{
        return this.http.get<ApiRequestObject>(`${environment.baseUrl}users/${company.id}/set-main`).pipe(
            map((resp: ApiRequestObject) => resp.result as User),
            catchError(this.error)
        );
    }
}
