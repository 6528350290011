import { NgModule } from '@angular/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { CenteredLayoutModule } from 'app/layout/layouts/horizontal/centered/centered.module';
import { EnterpriseLayoutModule } from 'app/layout/layouts/horizontal/enterprise/enterprise.module';
import { MaterialLayoutModule } from 'app/layout/layouts/horizontal/material/material.module';
import { ModernLayoutModule } from 'app/layout/layouts/horizontal/modern/modern.module';
import { ClassicLayoutModule } from 'app/layout/layouts/vertical/classic/classic.module';
import { ClassyLayoutModule } from 'app/layout/layouts/vertical/classy/classy.module';
import { CompactLayoutModule } from 'app/layout/layouts/vertical/compact/compact.module';
import { DenseLayoutModule } from 'app/layout/layouts/vertical/dense/dense.module';
import { FuturisticLayoutModule } from 'app/layout/layouts/vertical/futuristic/futuristic.module';
import { ThinLayoutModule } from 'app/layout/layouts/vertical/thin/thin.module';
import { SettingsModule } from 'app/layout/common/settings/settings.module';
import { SharedModule } from 'app/modules/shared/shared.module';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../modules/core/core.module';
import { MaterialCdkModule } from '../modules/material-cdk/material-cdk.module';
import { InputTextModalComponent } from 'app/modals/input-text-modal/input-text-modal.component';
import { OrganizationChartPositionAddQuickComponent } from 'app/modals/organization-chart-position-add-quick/organization-chart-position-add-quick.component';
import { CompaniesModule } from '../modules/companies/companies.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { UserModule } from 'app/modules/users/users.module';
import { ModalsModule } from 'app/modals/modals.module';

const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Horizontal navigation
    CenteredLayoutModule,
    EnterpriseLayoutModule,
    MaterialLayoutModule,
    ModernLayoutModule,

    // Vertical navigation
    ClassicLayoutModule,
    ClassyLayoutModule,
    CompactLayoutModule,
    DenseLayoutModule,
    FuturisticLayoutModule,
    ThinLayoutModule
];

@NgModule({
    declarations: [
        LayoutComponent,
        InputTextModalComponent,
        OrganizationChartPositionAddQuickComponent

    ],
    imports     : [
        CoreModule,
        MaterialCdkModule,
        SharedModule,
        SettingsModule,
        CKEditorModule,
        ...layoutModules
    ],
    exports     : [
        LayoutComponent,
        ...layoutModules
    ]
})
export class LayoutModule
{
}
