import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiRequestObject } from 'app/models/ApiRequestObject';
import { Dataloader } from 'app/models/dataloader';
import { User } from 'app/models/user';
import { FilesService } from 'app/services/files.service';
import { finalize, Subscription } from 'rxjs';

@Component({
  selector: 'app-file-config-upload',
  templateUrl: './file-config-upload.component.html',
  styleUrls: ['./file-config-upload.component.scss']
})
export class FileConfigUploadComponent implements OnInit {
  @Input() typeForm:string = "ThemeFile";
  @Input() requiredFileType?:string;
  @Input() user?: User;
  @Input() textInput: string = "Seleccionar imagen.";




   @Output() commands =  new EventEmitter<Array<string>>();
   @Output() dataloaderParse =  new EventEmitter<Dataloader>();
   @Output() waitUploadShared =  new EventEmitter<boolean>();


   private emitDataloaderParse() {
    this.dataloaderParse.emit(this.dataloader!);
  }
  private emitNewFileShared() {
    this.commands.emit(this.commandsList!);
  }
  private emitWaitUploadShared() {
    this.waitUploadShared.emit(this.waitUpload);
  }

  waitUpload = false;
  commandsList: Array<string>|null = null;
  reader = new FileReader();
  fileName = '';
  uploadProgress:number|null = null;
  uploadSub: Subscription|null = null;
  dataloader: Dataloader;

  constructor(private http: HttpClient, private filesSrv: FilesService) { }

  ngOnInit(): void {
    this.emitWaitUploadShared();
  }

  onFileSelected(event: any) {

    const file:File = event.target.files[0];
    this.waitUpload = true;
    this.commandsList  = null;

    if (file) {
        this.fileName = file.name;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("typeFile", this.typeForm);


        console.log(this.fileName);

        //const upload$ = this.userSrv.uploadLogoTheme(file , this.user!.Id!);
        //upload$.subscribe();

        const upload$ = this.filesSrv.uploadConfig(formData, this.typeForm );

        this.uploadSub = upload$.subscribe(event => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(100 * (event.loaded / event!.total!));
          }
          if (event.type == HttpEventType.Response){

            console.log("finish");
            console.log(event.body);
            if(this.typeForm.includes('data-loader')){

                let result: ApiRequestObject = event.body;

                this.dataloader = result.result as Dataloader;
                console.log(this.dataloader);
                this.emitDataloaderParse();

            }else{
                this.commandsList = event.body;
            }






            this.emitNewFileShared();
            this.waitUpload = false;

          }
        },error =>{
          console.log("Error")
        }
        );
    }else{

      this.waitUpload = false;
    }
}

cancelUpload() {
  this.uploadSub!.unsubscribe();
  this.reset();
}

reset() {
  this.uploadProgress = null;
  this.uploadSub = null;
}

}
