import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation';
import { FuseConfirmationConfigCustom } from 'app/models/fuseConfirmationConfigCustom';
import { Modal } from 'app/models/modal';

@Component({
    selector: 'app-input-text-modal',
    templateUrl: './input-text-modal.component.html',
    styleUrls: ['./input-text-modal.component.scss'],
    styles: [
        `
        .fuse-confirmation-dialog-panel {

            @screen md {
                @apply w-128;
            }

            .mat-mdc-dialog-container {

                .mat-mdc-dialog-surface {
                    padding: 0 !important;
                }
            }
        }
    `
    ],
    encapsulation: ViewEncapsulation.None
})
export class InputTextModalComponent {

    inputValue: string = '';
    data: FuseConfirmationConfigCustom;
    inputForm: UntypedFormGroup;

    constructor(
        private dialogRef: MatDialogRef<InputTextModalComponent>,
        @Inject(MAT_DIALOG_DATA) public dataInput: FuseConfirmationConfigCustom,
        private _formBuilder: UntypedFormBuilder,
    ) {
        console.log(this.dataInput);

        if (dataInput) {
            this.data = dataInput;
        }
        this.inputForm = this._formBuilder.group({
            name: [this.inputValue, Validators.required],
        });

    }

    get validate(): boolean{

        if(this.inputValue == "" || this.inputForm.invalid){
            return false;
        }

        return true;
    }

}
