<mat-toolbar color="primary" class="md-medium-tall toolbar">

	<button mat-icon-button id="menu" class="mr-4" (click)="inputSideNav!.toggle()">
		<mat-icon >menu</mat-icon>
	</button>

	<a routerLink="/home" >
		<img class="d-none d-lg-block img-brand mr-2" src="/assets/img/launcher_icon.png" />
		<img class="d-block d-lg-none img-brand img-brand-sm mr-2" src="/assets/img/launcher_icon.png" />
	</a>

  <span>Agrotech Suite</span>

	<!-- <span class="spacer"></span> -->
	<span class="mx-auto" >
		<!-- <img class="img-brand-customers" src="{{ customLogo }}" *ngIf="!customLogo.includes('brand.svg')" /> -->
		<!-- <img class="img-brand-customers" src="/assets/img/Logo.png" /> -->
	</span>

	<span style="padding-right: 20px;">
		<button *ngIf="existPreview == true" mat-raised-button class="btn-warning m-1" (click)="previewTheme()">Dejar de previsualizar tema</button>
	</span>

	<!-- <span style="padding-right: 20px;">{{ nameUser }}</span> -->

	<div>

	  <a routerLink="/notifications/list" >
    	<mat-icon mat-icon-button id="notifications" [matBadge]="notificationsCount"  >notifications</mat-icon>
			<span class="cdk-visually-hidden">
				Notificaciones
			</span>
	    </a>
	</div>
</mat-toolbar>
