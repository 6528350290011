import { Component, Inject, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { GoalDetailDashboardDetailUser } from 'app/models/dashboardDetailUser';
@Component({
  selector: 'app-list-items-dashboard',
  templateUrl: './list-items-dashboard.component.html',
  styleUrls: ['./list-items-dashboard.component.scss']
})
export class ListItemsDashboardComponent {
    // Text related
    @Input() title: string;
    @Input() type_name: string;
    @Input() type_number: number;
    @Input() active_name: string;
    @Input() active_number: number;
    @Input() addressing_name: string;
    @Input() addressing_route: string;
    // Clor related.
    @Input() bgColor: string;
    @Input() titleColor: string = '#01949a';
    @Input() typeNameColor: string = '#01949a';
    @Input() typeNumberColor: string = '#01949a';
    @Input() activeNameColor: string = '#01949a';
    @Input() activeNumberColor: string = '#01949a';
    @Input() addressingNameColor: string = '#01949a';
    @Input() classButtonColor: string = '';

    @Input() listItems: GoalDetailDashboardDetailUser[] = [];

}
