import { Company, OrganizationChartsPosition } from './companies';
import CryptoJS from 'crypto-js';
import { Gender } from './gender';


export class UserImport {
    public id?: number;
    public id_department?: number;
    public id_position?: number;
    public id_position_parent?: number;
    public is_onboarding?: boolean;
    public name_department?: string;
    public name_position?: string;
    public name_position_parent?: string;
    public name?: string;
    public lastname?: string;
    public email?: string;
    public name_role?: string;
    public result?: string;
    public errors?: string[];
    public warnings?: string[];

}
export class User {

    public id?: number;
    public name?: string;
    public lastname?: string;
    public email?: string;
    public password?: string;
    public company?: string;
    public avatar?: string;
    public status?: string;
    public username?: string;
    public position?: string;
    public about?: string;
    public phone?: string;
    public country?: string;
    public language?: string;

    public active: boolean = false;
    public id_role?: number;
    public bday_date?: Date;
    public is_onboarding?: boolean;

    public role?: Role;
    public companies?: Company[];
    public organization_chart_positions?: OrganizationChartsPosition[];



    public created_at?: Date;
    public updated_at?: Date;
    public birthday?: Date;
    public gender?: Gender;
    public is_hight_risk?: "Disability" | "SocialRisk" | "Other" | "None";
    public hight_risk_description?: string;

    public cp?: string;
    public is_admin?: boolean;
    public info?: UserLicenciaInfo;
    public licencias?: Licencia[];
    public IconRole?: string;

    public population?: string;
    public location?: string;
    public address?: string;

    public authToken?: string;
    public NotificationsCount?: number;
    public contactFormsUnread?: number;


    public id_main_position?: number;
    public main_position?: OrganizationChartsPosition;

    public company_active_id: number;
    public active_company: Company;

    public info_assessment?: UserInfoAssessment;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }

    fix_dates() {
        this.created_at = new Date(this.created_at!);
    }

    get isDeveloper() {
        if (this.role && this.role!.tag) {
            return this.role!.tag == 'dev' ? true : false;
        }
        return false;
    }
    get isAdmin() {
        if (this.role && this.role!.tag) {
            return this.role!.tag == 'admin' ? true : false;
        }
        return false;
    }
    get isConsultor() {
        if (this.role && this.role!.tag) {
            return this.role!.tag == 'consultor' ? true : false;
        }
        return false;
    }
    get isBusinessAdmin() {
        if (this.role && this.role!.tag) {
            return this.role!.tag == 'business-admin' ? true : false;
        }
        return false;
    }
    get isBusinessController() {
        if (this.role && this.role!.tag) {
            return this.role!.tag == 'business-controller' ? true : false;
        }
        return false;
    }
    get isBusinessAdminOrController() {
        return this.isBusinessAdmin || this.isBusinessController;
    }
    get isProfessionalBusiness() {
        if (this.role && this.role!.tag) {
            return this.role!.tag == 'professional-business' ? true : false;
        }
        return false;
    }
    get isProfessionalFreelance() {
        if (this.role && this.role!.tag) {
            return this.role!.tag == 'professional-freelance' ? true : false;
        }
        return false;
    }

    setIconRole(): void {
        if (this.isProfessionalFreelance || this.isProfessionalBusiness) {
            this.IconRole = "fa fa-user";
        } else if (this.isAdmin || this.isDeveloper) {
            this.IconRole = "fas fa-user-shield";
        } else if (this.isBusinessAdmin) {
            this.IconRole = "fas fa-users";
        } else if (this.isBusinessController || this.isConsultor) {
            this.IconRole = "fa fa-users-cog";
        } else {
            this.IconRole = "";
        }

    }

    includeIn(users: User[]):boolean{
        users.forEach(element => {
            if(element.id == this.id){
                return true
            }
        });
        return false;
    }

    copy(user: User){
        this.name = user.name;
        this.lastname = user.lastname;
        this.company = user.company;
        this.username = user.username;
        this.avatar = user.avatar;
        this.about = user.about;
        this.phone = user.phone;
        this.country = user.country;
        this.language = user.language;
        this.email = user.email;
        this.birthday = user.birthday;
        this.gender = user.gender;
        this.is_hight_risk = user.is_hight_risk;
        this.hight_risk_description = user.hight_risk_description;
    }
}


export class Role {
    id?: number;
    tag?: string;
    name?: string;
    intern_name?: string;
    icon?: string;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }

    get isDeveloper() {
        return this.tag == 'dev' ? true : false;
    }
    get isAdmin() {
        return this.tag == 'admin' ? true : false;
    }
    get isConsultor() {
        return this.tag == 'consultor' ? true : false;
    }
    get isBusinessAdmin() {
        return this.tag == 'business-controller' ? true : false;
    }
    get isBusinessController() {
        return this.tag == 'business-controller' ? true : false;
    }
    get isProfessionalBusiness() {
        return this.tag == 'professional-business' ? true : false;
    }
    get isProfessionalFreelance() {
        return this.tag == 'professional-freelance' ? true : false;
    }


    setIcon() {
        if (this.tag) {
            if (this.tag! == 'user') {
                this.icon = "fa fa-user";
            } else if (this.tag! == 'admin') {
                this.icon = "fas fa-user-shield";
            } else if (this.tag! == 'admin-dev') {
                this.icon = "fas fa-user-shield";
            } else if (this.tag! == 'fabricant') {
                this.icon = "fas fa-users";
            } else if (this.tag! == 'test') {
                this.icon = "fa fa-users-cog";
            }
        }
        this.icon = "";
    }
}


export class Payload {
    iss?: string
    iat?: number
    exp?: number
    nbf?: number
    jti?: string
    sub?: string
    prv?: string
    userId?: number;
    email?: string;
    name?: string;
    role?: string;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }

}


export class Licencia {
    public id?: number;
    public codigo?: string;
    public nombre?: string;
    public nombreInterno?: string;
    public precio?: number;
    public invitacion?: boolean;
    public estado?: number;
    public created_at?: Date;
    public updated_at?: Date;
    public usuarios?: User[];
    public info?: UserLicenciaInfo;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}



export class UserLicenciaInfo {
    public idUsuario?: number;
    public idLicencia?: number;
    public coste?: number;
    public start_at?: Date;
    public finish_at?: Date;


    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }

    fix_dates() {
        this.start_at = new Date(this.start_at!);
        this.finish_at = new Date(this.finish_at!);
    }

    get days(): number {

        if (this.finish_at) {
            //let start = moment().utc();
            //var duration = moment.duration(start.diff(this.finish_at!));
            return 0;
            //return duration.asDays();
        }
        return 0;

    }

}

export class UserInfoAssessment {
    public is_read_notification?: boolean;
    public is_completed?: boolean;
    public is_mail_send?: boolean;
    public start_time?: Date;
    public end_time?: Date;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}

export class UserWithOrganizationChartsPosition{
    public user : User;
    public position : OrganizationChartsPosition;
}
