import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, of, ReplaySubject, tap } from 'rxjs';
import { environment } from "environments/environment";


@Injectable({
    providedIn: 'root'
})
export class NavigationCustomService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    constructor(private http: HttpClient, router: Router, public authSrv: AuthenticationService) {


    }

    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    get(): Observable<Navigation>
    {
        let nav = this.getNavigation();
        let navFull: Navigation = { 'default': nav, 'compact': nav, 'futuristic': nav, 'horizontal' : nav };
        this._navigation.next(navFull);

        return of(navFull);
    }

    getNavigation(): FuseNavigationItem[]{

        let navigation: FuseNavigationItem[]|any[];


        if(this.authSrv.currentUserValue && this.authSrv.currentUserValue.isAdmin){

            let numForms =this.authSrv.currentUserValue.contactFormsUnread;
            let aux:any = {};

            if(numForms > 0){
                aux =
                {
                    id   : 'contact-forms',
                    title: 'Menu.ContactForms',
                    type : 'basic',
                    icon : 'heroicons_outline:annotation',
                    link : '/contact-forms',
                    badge: {
                        title  : numForms.toString(),
                        classes: 'w-5 h-5 bg-white text-hr rounded-full'
                    }
                };
            }else{
                aux= {
                    id   : 'contact-forms',
                    title: 'Menu.ContactForms',
                    type : 'basic',
                    icon : 'heroicons_outline:annotation',
                    link : '/contact-forms'
                };
            }

            navigation = [
                {
                    id   : 'home',
                    title: 'Menu.Home',
                    type : 'basic',
                    icon : 'heroicons_outline:home',
                    link : '/dashboard'
                },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },

                {
                    id   : 'users',
                    title: 'Menu.Users',
                    type : 'basic',
                    icon : 'heroicons_outline:user-group',
                    link : '/users'
                },
                {
                    id   : 'companies',
                    title: 'Menu.Companies',
                    type : 'basic',
                    icon : 'mat_outline:business',
                    link : '/companies'
                },
                {
                    id   : 'professional-profile',
                    title: 'Menu.ProfessionalProfile',
                    type : 'basic',
                    icon : 'heroicons_outline:user-add',
                    link : '/professional-profiles'
                },
                {
                    id   : 'competences',
                    title: 'Menu.Competences',
                    type : 'basic',
                    icon : 'mat_outline:auto_stories',
                    link : '/competences'
                },
                {
                    id: 'courses',
                    title: 'Formación',
                    type: 'basic',
                    icon: 'mat_outline:library_books',
                    link: '/courses'
                },
                { ...aux },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },
                {
                    id   : 'competences',
                    title: 'Menu.Assessments',
                    type : 'basic',
                    icon : 'mat_outline:assignment',
                    link : '/assessments'
                },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },
                {
                    id   : 'account',
                    title: 'Menu.Account',
                    type : 'basic',
                    icon : 'mat_outline:settings',
                    link : '/account'
                },
                (environment.showFileManager)?{
                    id   : 'apps.file-manager',
                    title: 'Recursos',
                    type : 'basic',
                    icon: 'heroicons_outline:folder',
                    link : '/file-manager'
                }:{},
                (environment.showDataLoader)?{
                    id   : 'apps.data-loader',
                    title: 'Data loader',
                    type : 'basic',
                    icon: 'heroicons_outline:cloud-upload',
                    link : '/data-loader'
                }:{},
                {
                    id      : 'apps.help-center',
                    title   : 'Help Center',
                    type    : 'collapsable',
                    icon    : 'heroicons_outline:support',
                    link    : '/helper-center',
                    children: [
                        (environment.showFAQs)?{
                            id   : 'apps.help-center.faqs',
                            title: 'FAQs',
                            type : 'basic',
                            link : '/faqs'
                        }:{},
                        {
                            id   : 'apps.help-center.support',
                            title: 'Soporte',
                            type : 'basic',
                            link : '/helper-center/support'
                        }
                    ]
                },
            ];
        }else if(this.authSrv.currentUserValue && (this.authSrv.currentUserValue.isBusinessAdmin || this.authSrv.currentUserValue.isBusinessController)){
            navigation = [
                {
                    id   : 'home',
                    title: 'Menu.Home',
                    type : 'basic',
                    icon : 'heroicons_outline:home',
                    link : '/dashboard'
                },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },

                {
                    id   : 'users',
                    title: 'Menu.Employees',
                    type : 'basic',
                    icon : 'heroicons_outline:user-group',
                    link : '/users'
                },
                {
                    id   : 'company',
                    title: 'Menu.Company',
                    type : 'basic',
                    icon : 'mat_outline:business',
                    link : '/company'
                },
                {
                    id   : 'professional-profile',
                    title: 'Menu.ProfessionalProfile',
                    type : 'basic',
                    icon : 'heroicons_outline:user-add',
                    link : '/professional-profiles'
                },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },
                {
                    id   : 'competences',
                    title: 'Menu.Assessments',
                    type : 'basic',
                    icon : 'mat_outline:assignment',
                    link : '/assessments'
                },
                {
                    id: 'courses',
                    title: 'Formación',
                    type: 'basic',
                    icon: 'mat_outline:library_books',
                    link: '/courses'
                },
                {
                    id   : 'objectives',
                    title: 'Menu.Objectives',
                    type : 'basic',
                    icon : 'heroicons_outline:academic-cap',
                    link : '/objectives'
                },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },
                {
                    id   : 'account',
                    title: 'Menu.Account',
                    type : 'basic',
                    icon : 'mat_outline:settings',
                    link : '/account'
                },
                (environment.showFileManager)?{
                    id   : 'apps.file-manager',
                    title: 'Recursos',
                    type : 'basic',
                    icon: 'heroicons_outline:folder',
                    link : '/file-manager'
                }:{},
                (environment.showDataLoader)?{
                    id   : 'apps.data-loader',
                    title: 'Data loader',
                    type : 'basic',
                    icon: 'heroicons_outline:cloud-upload',
                    link : '/data-loader'
                }:{},
                {
                    id      : 'apps.help-center',
                    title   : 'Help Center',
                    type    : 'collapsable',
                    icon    : 'heroicons_outline:support',
                    link    : '/helper-center',
                    children: [
                        (environment.showFAQs)?{
                            id   : 'apps.help-center.faqs',
                            title: 'FAQs',
                            type : 'basic',
                            link : '/faqs'
                        }:{},
                        {
                            id   : 'apps.help-center.support',
                            title: 'Soporte',
                            type : 'basic',
                            link : '/helper-center/support'
                        }
                    ]
                },
            ];
        }else if(this.authSrv.currentUserValue && (this.authSrv.currentUserValue.isAdmin || this.authSrv.currentUserValue.isProfessionalFreelance )){
            navigation = [
                {
                    id   : 'home',
                    title: 'Menu.Home',
                    type : 'basic',
                    icon : 'heroicons_outline:home',
                    link : '/dashboard'
                },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },

                {
                    id   : 'users',
                    title: 'Menu.Employees',
                    type : 'basic',
                    icon : 'heroicons_outline:user-group',
                    link : '/users'
                },
                {
                    id   : 'companies',
                    title: 'Menu.Companies',
                    type : 'basic',
                    icon : 'mat_outline:business',
                    link : '/companies'
                },
                {
                    id   : 'professional-profile',
                    title: 'Menu.ProfessionalProfile',
                    type : 'basic',
                    icon : 'heroicons_outline:user-add',
                    link : '/professional-profiles'
                },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },
                {
                    id   : 'competences',
                    title: 'Menu.Assessments',
                    type : 'basic',
                    icon : 'mat_outline:assignment',
                    link : '/assessments'
                },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },
                {
                    id   : 'account',
                    title: 'Menu.Account',
                    type : 'basic',
                    icon : 'mat_outline:settings',
                    link : '/account'
                },
                {
                    id      : 'apps.help-center',
                    title   : 'Help Center',
                    type    : 'collapsable',
                    icon    : 'heroicons_outline:support',
                    link    : '/helper-center',
                    children: [
                        (environment.showFAQs)?{
                            id   : 'apps.help-center.faqs',
                            title: 'FAQs',
                            type : 'basic',
                            link : '/faqs'
                        }:{},
                        {
                            id   : 'apps.help-center.support',
                            title: 'Soporte',
                            type : 'basic',
                            link : '/helper-center/support'
                        }
                    ]
                },
            ];

        }else if(this.authSrv.currentUserValue){
            navigation = [
                {
                    id   : 'home',
                    title: 'Menu.Home',
                    type : 'basic',
                    icon : 'heroicons_outline:home',
                    link : '/dashboard'
                },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },


                {
                    id   : 'competences',
                    title: 'Menu.Assessments',
                    type : 'basic',
                    icon : 'mat_outline:assignment',
                    link : '/assessments'
                },
                {
                    id  : 'divider-1',
                    type: 'divider'
                },
                {
                    id   : 'account',
                    title: 'Menu.Account',
                    type : 'basic',
                    icon : 'mat_outline:settings',
                    link : '/account'
                },
                (environment.showFileManager)?{
                    id   : 'apps.file-manager',
                    title: 'Recursos',
                    type : 'basic',
                    icon: 'heroicons_outline:folder',
                    link : '/file-manager'
                }:{},
                (environment.showDataLoader)?{
                    id   : 'apps.data-loader',
                    title: 'Data loader',
                    type : 'basic',
                    icon: 'heroicons_outline:cloud-upload',
                    link : '/data-loader'
                }:{},
                {
                    id      : 'apps.help-center',
                    title   : 'Help Center',
                    type    : 'collapsable',
                    icon    : 'heroicons_outline:support',
                    link    : '/helper-center',
                    children: [
                        (environment.showFAQs)?{
                            id   : 'apps.help-center.faqs',
                            title: 'FAQs',
                            type : 'basic',
                            link : '/faqs'
                        }:{},
                        {
                            id   : 'apps.help-center.support',
                            title: 'Soporte',
                            type : 'basic',
                            link : '/helper-center/support'
                        }
                    ]
                },
            ];
        }else{
            navigation = [];
        }



        return navigation;

    }



}
