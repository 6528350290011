import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-custom',
  templateUrl: './loading-custom.component.html',
  styleUrls: ['./loading-custom.component.scss']
})
export class LoadingCustomComponent implements OnInit {
  @Input() loading: boolean =false;

  /*public options: AnimationOptions = {
    path: '/assets/animation/16651-cpu-chip.json',
  };*/


  constructor() { }

  ngOnInit(): void {
  }

}
