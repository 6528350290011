import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize, Subscription } from 'rxjs';
import { User } from 'app/models/user';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'file-upload-component',
  templateUrl: './file-upload-component.component.html',
  styleUrls: ['./file-upload-component.component.scss']
})
export class FileUploadComponentComponent implements OnInit {
  @Input()
  requiredFileType?:string;
   @Input() user?: User;


   @Output() newFileShared =  new EventEmitter<string>();
   @Output() waitUploadShared =  new EventEmitter<boolean>();


  private emitNewFileShared() {
    this.newFileShared.emit(this.urlNewFile!);
  }
  private emitWaitUploadShared() {
    this.waitUploadShared.emit(this.waitUpload);
  }

  waitUpload = false;
  urlNewFile: string|null = null;
  reader = new FileReader();
  fileName = '';
  uploadProgress:number|null = null;
  uploadSub: Subscription|null = null;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.emitWaitUploadShared();
  }

  onFileSelected(event: any) {

    const file:File = event.target.files[0];
    this.waitUpload = true;
    this.urlNewFile  = null;

    if (file) {
        this.fileName = file.name;
        const formData = new FormData();
        formData.append("File", file);
        formData.append("TypeFile", "ThemeFile");
        formData.append("IdUser", this.user!.id!.toString());

        console.log(this.fileName);

        //const upload$ = this.userSrv.uploadLogoTheme(file , this.user!.id!);
        //upload$.subscribe();

        /*const upload$ = this.filesSrv.upload(formData );

        this.uploadSub = upload$.subscribe(event => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(100 * (event.loaded / event!.total!));
          }
          if (event.type == HttpEventType.Response){
            console.log("finish");
            console.log(event.body);
            this.urlNewFile = event.body;
            this.emitNewFileShared();
            this.waitUpload = false;

          }
        },error =>{
          console.log("Error")
        }
        ); */
    }else{

      this.waitUpload = false;
    }
}

cancelUpload() {
  this.uploadSub!.unsubscribe();
  this.reset();
}

reset() {
  this.uploadProgress = null;
  this.uploadSub = null;
}

}
