export enum StatusApiRequestObject {
    OK = "OK",
    ERROR = "ERROR",
    WARNING = "WARNING",
    EMPTY = ""
  }

export class ApiRequestObject {

  public status?: StatusApiRequestObject;
  public msg?: string;
  public result?: Object;
  public code?: number;
  public title?: string;
  public link?: string;
  public id?: number;

  constructor( object? : any ){
    if(object){
      this.status = (object!.status) ? object!.status : null;
      this.msg = (object.msg) ? object.msg : null;
      this.result = (object.result) ? object.result : null;
      this.code = (object.result) ? object.code : null;
    }else{
      this.status = StatusApiRequestObject.EMPTY
      this.msg = ""
      this.result = [];
      this.code = 0;
    }
  }

  static error(msg?: string) : ApiRequestObject{
    return new ApiRequestObject({'status' : 'ERROR' , 'msg' : msg??''});
  }

  static success(msg?: string) : ApiRequestObject{
    return new ApiRequestObject({'status' : 'OK' , 'msg' : msg??'', 'code' : 200});
  }

  static modal(msg?: string, code?: number) : ApiRequestObject{
    return new ApiRequestObject({'status' : 'MODAL' , 'msg' : msg??'', 'code' : code??0});
  }

  static errorServer(msg?: string, code?: number) : ApiRequestObject{
    return new ApiRequestObject({'status' : 'ERROR' , 'msg' : msg??'', 'code' : code??0});
  }



}


export class ListResult {

  public Total: number = 0;
  public Count: number = 0;
  public Filter: number = 0;
  public Result  = [];

}
