<button class="ml-2"
    mat-flat-button
    [color]="'primary'"
    [disabled]="disabled || is_loading"
    >
    <span [ngClass]="is_loading?'pl-4':''">
        <ng-container *ngIf="!is_loading">
            {{ text }}
        </ng-container>
        <ng-container *ngIf="is_loading">
            {{ text_loading }}
        </ng-container>
    </span>

    <mat-icon *ngIf="is_loading">
        <mat-spinner color="accent" diameter="20">
        </mat-spinner>
    </mat-icon>
</button>
