import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { UtilsService } from './utils.service';
import {  Payload, User } from 'app/models/user';
import { RememberPasswordToken } from '../models/rememberPasswordToken';
import { LoginObject } from '../models/LoginObject';
import { ApiRequestObject, StatusApiRequestObject } from '../models/ApiRequestObject';
import { DateTime } from 'luxon';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import jwt_decode from "jwt-decode";
import { Company } from '../models/companies';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject?: BehaviorSubject<User | null>;
    public currentUser?: Observable<User | null>;

    constructor(
        private http: HttpClient,
        private router: Router,
        private utils: UtilsService,
        private _userService: UserService

    ) {
        if (localStorage.getItem('currentUser')) {
            this.currentUserSubject = new BehaviorSubject<User | null>(new User(JSON.parse(<string>localStorage.getItem('currentUser'))));
        } else {
            this.currentUserSubject = new BehaviorSubject<User | null>(null);
        }
        this.currentUser = this.currentUserSubject!.asObservable();
    }


    /**
     * Devuelve el usuario actual del servicio
     *
     * @readonly
     * @type {User}
     * @memberof AuthenticationService
     */
    public get currentUserValue(): User | null {
        return this.currentUserSubject!.value;
    }
    public get currentUserTo(): User | null {
        return <User>new User(this.currentUserSubject!.value!);
    }

    getRole(): string {
        // 1: Administrador

        return new Payload({ ...jwt_decode(this.currentUserValue.authToken)}).role??'';

    };


    /**
     * Devuelve si el usuario actual está logado en el sistema
     *
     * @readonly
     * @type {boolean}
     * @memberof AuthenticationService
     */
    public get isLogedUser(): boolean {

        // console.log('currentUserValue ', this.currentUserValue);

        if (this.currentUserValue) {
            let dNow = new Date();
            /*
            let dExp = new Date(this.currentUserValue.AlertExpirydate!);
            if (dExp.getTime() < dNow.getTime()){

              console.log('isLogedUser ', false);
              return false;
            }*/
        } else {
            console.log('isLogedUser ', false);
            return false;
        }
        console.log('isLogedUser ', true);
        return true;
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {

        // Check the access token availability
        if ( !this.currentUserValue )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.currentUserValue.authToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    /**
     * Ejecuta el login en el sistema
     *
     * @param {string} username
     * @param {string} password
     * @returns
     * @memberof AuthenticationService
     */

    public login(loginObj: LoginObject): Observable<ApiRequestObject> {
        console.log(loginObj);
        return this.http.post<ApiRequestObject>(`${environment.baseUrl}login`, loginObj).pipe(
            catchError(this.error)
        );
    }


    public changeNotification(add: boolean) {
        let user: User = <User>new User(JSON.parse(localStorage.getItem('currentUser')!));

        if (add == true) {
            user.NotificationsCount = user.NotificationsCount! + 1;
        } else {
            if (user.NotificationsCount! > 0) {
                user.NotificationsCount = user.NotificationsCount! - 1;
            }
        }

        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject!.next(new User(user));
    }
    public clearNotification() {
        let user: User = <User>JSON.parse(localStorage.getItem('currentUser')!);

        user.NotificationsCount = 0;

        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject!.next(new User(user));
    }


    public updateToken(user: User) {
        this._userService.user = new User( <User>user);
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('currentToken', user!.authToken!);
        this.setLastUpdate();
        this.currentUserSubject!.next(user);
    }
    public updateUser(user: User) {
        this._userService.user = new User( <User>user);
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject!.next(user);
    }

    public updateActiveCompany(company: Company) {
        let user = this.currentUserValue;
        user.active_company = new Company( <Company>company);

        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject!.next(user);
    }

    getCurrentToken(): string | null {

        var token = localStorage.getItem('currentToken');
        //console.log("token ", token);
        return (token) ? token : null;
    };


    /**
     * Quita el login del sistema, pero sólo en local.
     *
     * @memberof AuthenticationService
     */
    logout(): Observable<any> {

        localStorage.clear();
        localStorage.setItem('lang', "es");
        this.currentUserSubject!.next(new User({}));
        //this.router.navigate(['/auth/login']);
        //console.log("Sesión terminada.");
        this.router.navigate(['/sign-in']);
        //window.open('/sign-in');
        return of(true);
    }

    clear() {

        localStorage.clear();
        localStorage.setItem('lang', "es");
        this.currentUserSubject!.next(new User({}));
    }


    // Generar token per resetejar password por email
    rememberPass(mail: string): Observable<RememberPasswordToken> {
        return this.http.post<RememberPasswordToken>(`${environment.baseUrl}login/rememberPass`, { 'Username': mail }).pipe(
            catchError(this.error)
        );
    }

    // Comprovar existeix token
    isValidToken(token: string) {
        return this.http.get<boolean>(`${environment.baseUrl}login/IsValidToken?token=${token}`).pipe();
    }

    // Camviar contrasenya by token
    changePassword(token: string, password: string): Observable<User> {
        let us: RememberPasswordToken;
        us = new RememberPasswordToken;
        us.Token = token;
        us.Username = password;
        us.Email = "";
        return this.http.post<User>(`${environment.baseUrl}login/changePassword`, us).pipe(
            catchError(this.error)
        );
    }


    // Error handling
    error(error: HttpErrorResponse) {

        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);

        let res: ApiRequestObject = ApiRequestObject.error(errorMessage);

        return throwError(() => res);
    }

    verificaAutenticacion(online: boolean): Observable<boolean> {

        console.log('isLogedUser ', this.isLogedUser);

        if (!online) {
            return of(this.isLogedUser)
        }

        if (!this.isLogedUser) {
            return of(false);
        }
        /*
        this.info().subscribe({
          next: (res: User) =>{

            return of(true);
          },
          error: (err:any) =>{
            return of(false);
          }
        });*/

        return this.info().pipe(
            map((resp: User) => {
                console.log("verificaAutenticacion");
                console.log(resp);
                if (resp) {
                    this.currentUserSubject!.next(new User(resp));
                    //this._navigation.reloadNavigation();

                    //this.updateToken( new User(resp) );
                    return true;
                }
                return false;
            })
        );
    }


    info(): Observable<User> {
        return this.http.get<ApiRequestObject>(`${environment.baseUrl}info`).pipe(
            map((result: ApiRequestObject) => {


                let user =  new User({ ...result.result });
                return user;
            }),
            catchError(this.error)
        );
    }

    loginAutenticacion(): Observable<boolean> {
        console.log("isLogedUser", this.isLogedUser);
        return of(this.isLogedUser)
    }


    setLastUpdate() {
        let lastUpdate = DateTime.now().plus({ 'day': 1 }).toFormat("");
        localStorage.setItem('lastUpdate', lastUpdate);
    }


    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this.http.get(`${environment.baseUrl}info`).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((result: ApiRequestObject) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if ( result.status == StatusApiRequestObject.OK )
                {
                    let user = new User(<User> { ...result.result});
                    this.updateToken(user);
                    //this.accessToken = response.accessToken;
                    //this._userService.user = new User(<User> result.result);


                    // Return true
                    return of(true);
                }

                // Return false
                return of(false);
            })
        );
    }

}
