/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'home',
        title: 'Menu.Home',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/dashboard'
    },
    {
        id  : 'divider-1',
        type: 'divider'
    },

    {
        id   : 'users',
        title: 'Menu.Users',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/users'
    },
    {
        id   : 'companies',
        title: 'Menu.Companies',
        type : 'basic',
        icon : 'mat_outline:business',
        link : '/companies'
    },
    {
        id   : 'professional-profile',
        title: 'Menu.ProfessionalProfile',
        type : 'basic',
        icon : 'heroicons_outline:user-add',
        link : '/professional-profiles'
    },
    {
        id   : 'competences',
        title: 'Menu.Competences',
        type : 'basic',
        icon : 'mat_outline:auto_stories',
        link : '/competences'
    },
    {
        id  : 'divider-1',
        type: 'divider'
    },
    {
        id   : 'competences',
        title: 'Menu.Assessments',
        type : 'basic',
        icon : 'mat_outline:assignment',
        link : '/assessments'
    },
    {
        id  : 'divider-1',
        type: 'divider'
    },
    {
        id   : 'account',
        title: 'Menu.Account',
        type : 'basic',
        icon : 'mat_outline:settings',
        link : '/account'
    },
    {
        id      : 'apps.help-center',
        title   : 'Help Center',
        type    : 'collapsable',
        icon    : 'heroicons_outline:support',
        link    : '/helper-center',
        children: [
            {
                id   : 'apps.help-center.faqs',
                title: 'FAQs',
                type : 'basic',
                link : '/helper-center/faqs'
            },
            {
                id   : 'apps.help-center.guides',
                title: 'Guides',
                type : 'basic',
                link : '/helper-center/guides'
            },
            {
                id   : 'apps.help-center.support',
                title: 'Support',
                type : 'basic',
                link : '/helper-center/support'
            }
        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
