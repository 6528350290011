import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Location } from '@angular/common';
import { LinkModal, Modal } from '../models/modal';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from '../modals/info-modal/info-modal.component';
import { FuseConfirmationConfig } from '@fuse/services/confirmation';
import { FuseConfirmationConfigCustom } from '../models/fuseConfirmationConfigCustom';
import { NavigationService } from 'app/core/navigation/navigation.service';

@Injectable({ providedIn: 'root' })
export class UtilsService {

    constructor(
        private http: HttpClient,
        public location: Location,
        public dialog: MatDialog

    ) {

    }



    ///////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////// INTERNS/////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////

    getClientIp(): Observable<any> {
        return this.http.get<any>('https://api.ipify.org/?format=json');
    }


    public getAppDomain(): string {
        let domain: string = '';
        const angularRoute = this.location.path;
        const url = window.location.href;
        domain = url.replace(angularRoute.toString(), '');
        if (domain.endsWith('/')) {
            domain = domain.slice(0, -1)
        }

        let start = "";

        if (domain.includes("http://")) {
            start = "http://";
        } else if (domain.includes("https://")) {
            start = "https://";
        }
        domain = domain.replace(start, "");

        let domainArray: string[];
        domainArray = domain.split("/");

        return start + domainArray[0];
    }

    public dec2hex(dec: any) {
        return dec.toString(16).padStart(2, "0")
    }

    public generateId(len: any): string {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }


    public hexToRGB(hex: string, alpha: number) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }



    public infoModal(msg: string, link?: LinkModal, title?: string) {
        let modal = new Modal();
        modal.Title = title ?? "Atención";
        modal.Body = msg
        modal.BtnAccept = "Continuar"
        modal.ClassType = "danger";
        modal.Link = link ?? undefined

        const dialogRef = this.dialog.open(
            InfoModalComponent,
            {
                autoFocus: 'dialog',
                width: "auto",
                minWidth: '600px',
                data: modal,
                closeOnNavigation: true,
                disableClose: false,
                panelClass: 'custom-modalbox'
            });
    }
    public calculateDiff(end: Date): number {
        return 0;
    }

    alert_error(title: string, error: string): FuseConfirmationConfig {

        return {
            "title": title,
            "message": error,
            "icon": {
                "show": true,
                "name": "heroicons_outline:exclamation",
                "color": "warn"
            },
            "actions": {
                "confirm": {
                    "show": false,
                },
                "cancel": {
                    "show": true,
                    "label": "Cerrar"
                }
            },
            "dismissible": true
        };
    }

    alert_create(title: string, texto: string, nombreInput: string, icono: string, colorIcono: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error', other = null): FuseConfirmationConfigCustom {
        return {
            "title": title,
            "message": texto,
            "nameInput": nombreInput,
            "other": other,
            "icon": {
                "show": true,
                "name": icono,
                "color": colorIcono
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": 'Dar de alta',
                    "color": "primary"
                },
                "cancel": {
                    "show": true,
                    "label": "Cancelar"
                }
            },
            "dismissible": true
        };
    }

    alert_succeful(title: string, texto: string, icono: string, colorIcono: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error'): FuseConfirmationConfig {
        return {
            "title": title,
            "message": texto,
            "icon": {
                "show": true,
                "name": icono,
                "color": colorIcono
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": "Aceptar",
                    "color": "primary"
                },
                "cancel": {
                    "show": false,
                    "label": "Cerrar"
                }
            },
            "dismissible": true
        };
    }

    alert_delete(title: string, texto: string): FuseConfirmationConfig {
        return {
            "title": title,
            "message": texto,
            "icon": {
                "show": true,
                "name": 'heroicons_outline:exclamation',
                "color": 'error'
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": "Eliminar",
                    "color": "warn"
                },
                "cancel": {
                    "show": true,
                    "label": "Cerrar"
                }
            },
            "dismissible": true
        };
    }

    alert_publish(title: string, texto: string): FuseConfirmationConfig {
        return {
            "title": title,
            "message": texto,
            "icon": {
                "show": true,
                "name": 'heroicons_outline:exclamation',
                "color": 'warning'
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": "Publicar",
                    "color": "warn"
                },
                "cancel": {
                    "show": true,
                    "label": "Cerrar"
                }
            },
            "dismissible": true
        };
    }

    alert_confirm(title: string, texto: string, icono: string, colorIcono: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error'): FuseConfirmationConfig {
        return {
            "title": title,
            "message": texto,
            "icon": {
                "show": true,
                "name": icono,
                "color": colorIcono
            },
            "actions": {
                "confirm": {
                    "show": true,
                    "label": "Aceptar",
                    "color": "primary"
                },
                "cancel": {
                    "show": true,
                    "label": "Cerrar"
                }
            },
            "dismissible": true
        };
    }


}
