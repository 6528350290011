import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { MaterialCdkModule } from '../material-cdk/material-cdk.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BlockDashboardComponent } from './components/block-dashboard/block-dashboard.component';
import { BlockGroupDashboardComponent } from './components/block-group-dashboard/block-group-dashboard.component';
import { RouterModule } from '@angular/router';
import { LoadingCustomComponent } from './components/loading-custom/loading-custom.component';
import { FileUploadComponentComponent } from './components/file-upload-component/file-upload-component.component';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { DisplayApiRequestObjectComponent } from './components/display-api-request-object/display-api-request-object.component';
import { GenericButtonComponent } from './components/generic-button/generic-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DefaultButtonComponent } from './components/default-button/default-button.component';
import { PartDashboardComponent } from './components/part-dashboard/part-dashboard.component';
import { FileConfigUploadComponent } from './components/file-config-upload/file-config-upload.component';
import { ListItemsDashboardComponent } from './components/list-items-dashboard/list-items-dashboard.component';
import { ChronologicalResultAssessmentChartComponent } from './components/chronological-result-assessment-chart/chronological-result-assessment-chart.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    BlockDashboardComponent,
    BlockGroupDashboardComponent,
    BreadcrumbComponent,
    LoadingCustomComponent,
    FileUploadComponentComponent,
    UserIconComponent,
    DisplayApiRequestObjectComponent,
    GenericButtonComponent,
    DefaultButtonComponent,
    PartDashboardComponent,
    FileConfigUploadComponent,
    ListItemsDashboardComponent,
    ChronologicalResultAssessmentChartComponent

  ],
  imports: [
    CoreModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialCdkModule,
    NgApexchartsModule
  ],
  exports: [
    RouterModule,
    ReactiveFormsModule,
    MaterialCdkModule,
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    BlockDashboardComponent,
    BlockGroupDashboardComponent,
    BreadcrumbComponent,
    LoadingCustomComponent,
    FileUploadComponentComponent,
    UserIconComponent,
    DisplayApiRequestObjectComponent,
    GenericButtonComponent,
    CoreModule,
    NgApexchartsModule,
    DefaultButtonComponent,
    PartDashboardComponent,
    FileConfigUploadComponent,
    ListItemsDashboardComponent,
    ChronologicalResultAssessmentChartComponent
  ]
})
export class SharedModule { }
