import { HttpErrorResponse, HttpHeaders } from "@angular/common/http"
import { DeviceModel } from "./device"
import { User } from "./user"

export class Modal   {
  Title: string = "Titulo"
  BtnAccept: string = "Aceptar"
  BtnCancel:string  = "Cancelar"
  Body:string = ""
  ClassType: string = "primary"
  User?: User;
  Data: any;
  Link?: LinkModal;


  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export class LinkModal{

  Text: string = "";
  Link: string = "";
  PreText: string = "";
  PostText: string = "";

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

}


export class BadRequest{
  modal?: string;
  ExceptionMessage: string = "";
  ExceptionType: String = "";
  Message: String = "";
  StackTrace: String ="";
}

