import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from 'app/modals/confirmation-modal/confirmation-modal.component';
import { Modal } from 'app/models/modal';

@Component({
  selector: 'app-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss']
})
export class GenericButtonComponent implements OnInit {


  @Input() txtBtn: string = "Eliminar";
  @Input() title: string = "";

  @Input() btnAccept: string = "Eliminar";
  @Input() classType: string = "danger";

  @Input() txt: string = "";
  @Output() confirmDelete = new EventEmitter<void>();

  constructor(
    public dialog: MatDialog,
    private router : Router,) { }

  ngOnInit(): void {
  }


  click(){

    let modal = new Modal();
    modal.Title = this.title??"Atención";
    modal.Body = this.txt;
    modal.BtnAccept = this.btnAccept;
    modal.ClassType = "this.ClassType";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'
    });


    dialogRef.afterClosed().subscribe( (result: boolean) => {
      if(result){
        this.confirmDelete.next();
      }
    });
  }


}
